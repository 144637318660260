import React, { useEffect, useState } from "react";
import "../AdminMenus/AdminLogIn.css";
import "../FinalizePayPage/FinalizePayPage.css";
import Footer from "../../components/Footer/Footer";
import background from "../models/background-2.jpg";
import { NavBarIfLoggedIn } from "../../middleware/IfLoggedIn";
import { authHeader } from "../../services/AuthHeader";
import "./historyform.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import PaginationReact from "react-js-pagination";
import moment from 'moment';
const { REACT_APP_API_URL } = process.env;

const LoginSubscription = () => {
  const history = useHistory();
  const [typeHistorical, settypeHistorical] = useState(true);
  const [myhistory, setmyhistory] = useState([]);
  const [myotherhistory, setmyotherhistory] = useState([]);
  const [pagemy, setpagemy] = useState(1);
  const [pageother, setpageother] = useState(1);
  const [countmy, setcountmy] = useState(1);
  const [countother, setcountother] = useState(1);
  const elementsPerPage = 15;
  const lnNumberTotalPages = 10;

  const handlePageChange = async (e) => {
    if(typeHistorical){
      setpagemy(e);
      var offset = ((e - 1) * 15);
      const result = await axios.post(`${REACT_APP_API_URL}/users/history`, { headers: authHeader(), status: typeHistorical, offset: offset });
      setmyhistory(result.data.data);
      setcountmy(result.data.count);
    }else{
      setpageother(e);
      var offset = ((e - 1) * 15);
      const result = await axios.post(`${REACT_APP_API_URL}/users/history`, { headers: authHeader(), status: typeHistorical, offset:offset });
      setmyotherhistory(result.data.data);
      setcountother(result.data.count);
    }
  };

  const changehistorical = async (status) => {
    settypeHistorical(status);
    if(!status && myotherhistory.length === 0){
      const result = await axios.post(`${REACT_APP_API_URL}/users/history`, { headers: authHeader(), status: status, offset:0 });
      setmyotherhistory(result.data.data);
      setcountother(result.data.count);
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  useEffect(() => {
    const ListHistory = async () => {
      const result = await axios.post(`${REACT_APP_API_URL}/users/history`, { headers: authHeader(), status:true, offset:0 });
      setmyhistory(result.data.data);
      setcountmy(result.data.count);
    };
    ListHistory();

    const statusSession = async () => {
      await axios(`${REACT_APP_API_URL}/users/session`, { headers: authHeader() }).catch((e) => {
        if(e.response.status === 503){
          localStorage.removeItem('authorization');
          Toast.fire({
            icon: 'danger',
            title: "The current session expired"
          });
          history.push('/login');
        }
      }); 
    };
    statusSession();
  }, []);
  
  return (
    <body>
      <NavBarIfLoggedIn></NavBarIfLoggedIn>
      <div className="page-title">
        <img src={background} alt="Catheter background"></img>
        <p>Historical</p>
      </div>
      <div className="page-container">
        <div className="admin-log-in-container">
          <div className="title-billing">
            {typeHistorical ?
              <h1>My history</h1>
              :
              <h1>Other Histories</h1>
            }
          </div>
          <div className="hl-details" style={{ marginTop: "5%" }}></div>
          <div className="div_table_response_new">
            <table className="table_response_new">
              <thead>
                <tr>
                  <th>Main Catheter</th>
                  <th>Products</th>
                  <th>Compatible</th>
                  <th>Date</th>
                  {!typeHistorical ? <th>User</th> : ""}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                { typeHistorical ?
                  myhistory ? myhistory.map((data, index) => (
                    <tr key={index}>
                      <td>{ data.Product.Name }</td>
                      <td>
                        <ul>
                          <li className="hiddenlist"></li>
                          { data.Producta ? <li>{data.Producta.Name}</li> : '' }
                          { data.Productb ? <li>{data.Productb.Name}</li> : '' }
                          { data.Productc ? <li>{data.Productc.Name}</li> : '' }
                        </ul>
                      </td>
                      <td>{ data.Status ? "Yes": "No" }</td>
                      <td>{ moment(data.CreatedAt).format("LL") }</td>
                      <td>
                        {
                          data.ProductC ?
                          <a href={"/choose-catheter/c/" + data.id}>View</a>
                          :
                          data.ProductB ?
                          <a href={"/choose-catheter/b/" + data.id}>View</a>
                          : 
                          <a href={"/choose-catheter/a/" + data.id}>View</a>
                        }
                      </td>
                    </tr>
                  )) : ''
                  :
                  myotherhistory ? myotherhistory.map((data, index) => (
                    <tr key={index}>
                      <td>{ data.Product.Name }</td>
                      <td>
                        <ul>
                          <li className="hiddenlist"></li>
                          { data.Producta ? <li>{data.Producta.Name}</li> : '' }
                          { data.Productb ? <li>{data.Productb.Name}</li> : '' }
                          { data.Productc ? <li>{data.Productc.Name}</li> : '' }
                        </ul>
                      </td>
                      <td>{ data.Status ? "Yes": "No" }</td>
                      <td>{ moment(data.CreatedAt).format("LL") }</td>
                      <td>{ data.User ? data.User.Name : '' } { data.User ? data.User.LastName : '' }</td>
                      <td>
                        {
                          data.ProductC ?
                          <a href={"/choose-catheter/c/" + data.id}>View</a>
                          :
                          data.ProductB ?
                          <a href={"/choose-catheter/b/" + data.id}>View</a>
                          : 
                          <a href={"/choose-catheter/a/" + data.id}>View</a>
                        }
                      </td>
                    </tr>
                  )) : ''
                }
              </tbody>
            </table>
            { typeHistorical ?
              <PaginationReact
                activePage={pagemy}
                itemsCountPerPage={elementsPerPage}
                totalItemsCount={countmy}
                pageRangeDisplayed={lnNumberTotalPages}
                onChange={(e) => handlePageChange(e)}
              />
              :
              <PaginationReact
                activePage={pageother}
                itemsCountPerPage={elementsPerPage}
                totalItemsCount={countother}
                pageRangeDisplayed={lnNumberTotalPages}
                onChange={(e) => handlePageChange(e)}
              />
            }
          </div>
        </div>
        <div className="list_order">
          <div className="title-billing">
            <h1>Historical</h1>
          </div>
          <div className="hl-details" style={{ marginTop: "5%" }}></div>
          <ul className="list_historical">
            <li className={ typeHistorical ? "active" : "" } onClick={() => changehistorical(true) }>My history</li>
            <li className={ !typeHistorical ? "active" : "" } onClick={() => changehistorical(false) }>Other Histories</li>
          </ul>
        </div>
      </div>
      <Footer></Footer>
    </body>
  );
};

export default LoginSubscription;
