import SideBar from "../../components/SideBar/SideBar";

const AdminMenu = () => {
  return (
    <div className="AppAdmin">
      <SideBar></SideBar>
    </div>
  );
};

export default AdminMenu;
