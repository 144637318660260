import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import { NavLink, Redirect } from 'react-router-dom';
import axios from "axios";
import { NavBarIfLoggedIn } from "../../middleware/IfLoggedIn";
import { authHeader } from "../../services/AuthHeader";
import background from "../models/background-2.jpg";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import "./Subscription.css";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import { useHistory  } from "react-router-dom";

const { REACT_APP_API_URL } = process.env;

const Subscription = () => {
  const history = useHistory();
  const [subscription, setSubscription] = useState(true);
  const [userStatus, setUserStatus] = useState(true);
  const [price, setPrice] = useState('Subscription plan coming soon with Catheter Buddy versión 2.0 release');
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  const payment_paypal = async() => { 
    setLoader(true);
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/users/create-payment`, { headers: authHeader(), price: price });
    if(result.data.status) {
      const link = result.data.data.links[0].href;

      localStorage.setItem('user_id_p', result.data.user_id);
      
      window.location.assign(link);
    }
    setLoader(false);
  }

  useEffect(() => {
    document.title = "Subscription | CatheterBuddy | Medical Device Compatibility Checker";
    document.getElementById('description_meta_utf8').setAttribute("content", "Access to product compatibility, share via email or whatsap, history of each compatibility, report non-existent products, user profile creation...");
    const fetchDataGeneral = async () => {
      const result = await axios.post(`${REACT_APP_API_URL}/users/subscription`, { headers: authHeader(), });
      setSubscription(result.data.status_subscriptions);
      setUserStatus(result.data.status_user);
      
    };
    fetchDataGeneral();
  }, []);

  return (
    <>
      <NavBarIfLoggedIn></NavBarIfLoggedIn>
      {loader ?
        <div className="preloaderpaypal">
          <div>
            <div className="loader">Loading...</div>
            <div className="logopp">
              <img src="/pp.png" style={{ width:'100%' }} />
            </div>
          </div>
        </div> : ''
      }
      <div className="page-title p">
        <img src={background} alt="Catheter background"></img>
        <p>Subscription</p>
      </div>
      <div className="page-container-subs">
        <div className="pay-container">
          <div className="title-pay">
            <p>Monthly Plan</p>
            <h1>{price}</h1>
          </div>
          <div className="hl-pay"></div>
          <div className="pay-benefits">
            <p>
              <span>
                <CheckCircleOutlineIcon />
              </span>
              access to product compatibility
            </p>
            <p>
              <span>
                <CheckCircleOutlineIcon />
              </span>
              share via email or whatsapp
            </p>
            <p>
              <span>
                <CheckCircleOutlineIcon />
              </span>
              history of each compatibility
            </p>
            <p>
              <span>
                <CheckCircleOutlineIcon />
              </span>
              report non-existent products
            </p>

            <p>
              <span>
                <CheckCircleOutlineIcon />
              </span>
              user profile creation
            </p>
          </div>
          <div className="subscription-button">
            {
              !userStatus ? <NavLink to={{ pathname: "/login/subscription" }}><Button variant="success" size="lg">Login and subscription</Button></NavLink> : 
              !subscription ? <Button variant="success" size="lg" onClick={() => { payment_paypal(); }}>subscribe</Button> :
              <span style={{ verticalAlign: "middle", textAlign: "center", display: "block", fontWeight: "bold", lineHeight: "3rem", fontSize: "18px" }}>User already subscribe</span>
            }
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Subscription;
