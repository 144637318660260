import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { NavBarIfLoggedIn } from "../../middleware/IfLoggedIn";
import { authHeader } from "../../services/AuthHeader";
import background from "../models/background-2.jpg";
import Footer from "../../components/Footer/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import './ExecutePayment.css';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
});

function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if(pair[0] == variable) {
            return pair[1];
        }
    }
    return false;
 }

const Feedback = () => {
    const [status, setStatus] = useState("");

    useEffect(() => {
        const fetchDataGeneral = async () => {
            const token = getQueryVariable('subscription_id');
            var user_id_p = localStorage.getItem('user_id_p');
            const result = await axios.post(`${process.env.REACT_APP_API_URL}/users/execute-payment`, { headers: authHeader(), token: token, user_id: user_id_p });
            setStatus(result.data.status);
            if(result.data.status){
                localStorage.removeItem('user_id_p');
                Toast.fire({
                    icon: 'success',
                    title: result.data.data
                });
            }else{
                Toast.fire({
                    icon: 'error',
                    title: result.data.data
                });
            }
        };
        fetchDataGeneral();
    }, []);

    return (
        <>
        <NavBarIfLoggedIn />
        <div className="page-title">
            <img src={background} alt="Catheter background"></img>
            <p>Feedback </p>
        </div>
        <div className="pageContainer" style={{ textAlign:'center' }}>
            <div className="pageTextFeedback">
            <div className="title-billing" style={{ margin:'0px' }}>
                <h1>Procesando pago</h1>
            </div>
            <div className="hl-details"></div>
                <div className="preloader-div">
                    <div className="preloader"></div>
                </div>
            </div>
        </div>
        <Footer />
        {
            status === false ? <Redirect to="/subscription"/> : ""          
        }
        {
            status === true ? <Redirect to="/choose-catheter"/> : ""          
        }
        </>
    );
};

export default Feedback;
