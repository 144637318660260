export const optionsMenuData = [
  {
    link: "/choose-catheter",
    title: "Product Compatibility Check",
    class: "Hover",
  },
  {
    link: "/history",
    title: "History Compatibility",
    class: "Hover",
  },
  {
    link: "/profile",
    title: "My Profile",
    class: "displayMobile Hover",
  },
  {
    link: "/profile/change-password",
    title: "Change password",
    class: "Hover",
  },
  {
    link: "/feedback",
    title: "Feedback",
    class: "displayMobile Hover",
  },
];

export const optionsMenuNotLogged = [
  {
    link: "/login",
    title: "Login",
    class: "displayMobile Hover",
  },
  {
    link: "/subscription",
    title: "Subscription",
    class: "displayMobile Hover",
  },
];
