import React, { useState } from "react";
import SideBar from "../../../components/SideBar/SideBar";
import "./AddElement.css";
import { Form, Button } from "react-bootstrap/";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { authHeader } from "../../../services/AuthHeader";

function AddProductType() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const history = useHistory();

  const handleSubmit = (e) => {
    const data = {
      Name: name,
      Description: description,
    };
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}/productTypes`, data, {
        headers: authHeader(),
      })
      .then((res) => {
        setName("");
        setDescription("");
        Swal.fire({
          title: "Success!",
          text: `${res.data.Name} has been added!`,
          icon: "success",
          confirmButtonText: "Ok",
        });
        history.push(`/admin/see-producttypes`);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          confirmButtonText: "Try Again",
        });
      });
  };

  return (
    <div className="AppAdmin">
      <SideBar></SideBar>
      <div className="users-title">
        <p>Add Product Type</p>
      </div>
      <div className="input-product-container">
        <Form>
          <Form.Group controlId="formGroupName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a product type"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formGroupDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>

          <div className="product-submit">
            <a href="/admin/see-producttypes">
              <Button size="lg" type="submit" onClick={handleSubmit}>
                <p>Add</p>
              </Button>
            </a>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddProductType;
