import dashboard from "../models/DASHBOARD.png";
import compatibility from "../models/Compatibility.png";
import sharewith from "../models/Share-with.png";
import historical from "../models/Historical.png";
import youraccount from "../models/Your-account.png";
import notifications from "../models/Notifications.png";

export const homepageData = [
  {
    img: dashboard,
    title: "Dashboard",
    text: "Simple and intuitive dashboard for a streamlined interactive experience",
  },
  {
    img: compatibility,
    title: "Product Compatibility",
    text: "CatheterBuddy enables you to check your medical devices compatibility in a few quick clicks on your mobile or computer device.",
    link: "/choose-catheter",
  },
  {
    img: sharewith,
    title: "Share",
    text: "Share your searches via WhatsApp, SMS or email",
  },
];

export const secondHomepageData = [
  {
    img: historical,
    title: "Historical",
    text: "Users will have access to their historical searches",
  },
  {
    img: youraccount,
    title: "Your Account",
    text: "Here you can find your CatheterBuddy personal details and historical information",
  },
  {
    img: notifications,
    title: "Notifications",
    text: "You will receive optional CatheterBuddy notifications on improvements and updates",
  },
];
