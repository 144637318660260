import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import PaginationReact from "react-js-pagination";
import Swal from "sweetalert2";
import "../../pages/AdminMenus/SeeModels/SeeElement.css";
import { authHeader } from "../../services/AuthHeader";
const { REACT_APP_API_URL } = process.env;

function SeeComponent() {
  const [data, setData] = useState([]);
  const history = useHistory();
  let { models } = useParams();
  let body = <></>;
  let swal = Swal;

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(`${REACT_APP_API_URL}/${models}/`);
      setData(result.data);
    };
    fetchData();
  }, [models]);

  let DeleteComponent = async (id, Name, LastName) => {
    swal
      .fire({
        title: `Are you sure you want to delete ${Name}?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          if (LastName) {
            swal.fire(
              "Deleted!",
              `${Name} ${LastName} has been deleted.`,
              "success"
            );
          } else {
            swal.fire("Deleted!", `${Name} has been deleted.`, "success");
          }
          await axios.delete(`${REACT_APP_API_URL}/${models}/${id}`, {
            headers: authHeader(),
          });
          const results = await axios(`${REACT_APP_API_URL}/${models}/`);
          setData(results.data);
        }
      })
      .then(() => {
        history.push("/products");
        history.push(`/admin/see-${models}`);
      });
  };

  //Pagination
  const [activePage, setCurrentPage] = useState(1);
  let itemsNumber = [];
  const elementsPerPage = 15;
  const lnNumberTotalPages = 11;
  const indexOfLastElements = activePage * elementsPerPage;
  const indexOfFirstElements = indexOfLastElements - elementsPerPage;
  const currentElements = data.slice(indexOfFirstElements, indexOfLastElements);

  for ( let number = 1; number <= Math.ceil(data.length / elementsPerPage); number++) {
      itemsNumber.push(<Pagination.Item key={number} onClick={() => handlePageChange(number)}>{number}</Pagination.Item>);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const CustomPagination = () => {
    return (
      <Pagination>
        <Pagination.Prev
          onClick={(prev) => setCurrentPage((prev) => prev - 1)}
          disabled={activePage === 1}
        />
        {itemsNumber}
        <Pagination.Next
          onClick={(prev) => setCurrentPage((prev) => prev + 1)}
          disabled={activePage === Math.ceil(data.length / elementsPerPage)}
        />
      </Pagination>
    );
  };

  switch (models) {
    case "products":
      body = (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>id</th>
                <th>Name</th>
                <th>Description</th>
                <th>Company</th>
                <th>Product Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentElements.map((product) => (
                <tr key={product.id}>
                  <td>{product.id}</td>
                  <td>{product.Name}</td>
                  <td>
                    {product.Description}
                  </td>
                  <td>{product.Company.Name}</td>
                  <td>{product.ProductType.Name}</td>
                  <td>
                    <Button
                      variant="info"
                      href={`/admin/edit-product/${product.id}`}
                      style={{ marginRight: "10px" }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => DeleteComponent(product.id, product.Name)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginationReact
            activePage={activePage}
            itemsCountPerPage={elementsPerPage}
            totalItemsCount={data.length}
            pageRangeDisplayed={lnNumberTotalPages}
            onChange={(e) => handlePageChange(e)}
          />
        </>
      );
      break;
    case "companies":
      body = (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>id</th>
                <th>Name</th>
                <th>Description</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentElements.map((company) => (
                <tr key={company.id}>
                  <td>{company.id}</td>
                  <td>{company.Name}</td>
                  <td>{company.Description}</td>
                  <td>
                    <Button
                      variant="info"
                      href={`/admin/edit-company/${company.id}`}
                    >
                      Edit
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => DeleteComponent(company.id, company.Name)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginationReact
            activePage={activePage}
            itemsCountPerPage={elementsPerPage}
            totalItemsCount={data.length}
            pageRangeDisplayed={lnNumberTotalPages}
            onChange={(e) => handlePageChange(e)}
          />
        </>
      );
      break;
    case "measuretypes":
      body = (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>id</th>
                <th>Name</th>
                <th>Description</th>
                <th>Slug</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentElements.map((measureType) => (
                <tr key={measureType.id}>
                  <td>{measureType.id}</td>
                  <td>{measureType.Name}</td>
                  <td>{measureType.Description}</td>
                  <td>{measureType.Slug}</td>
                  <td>
                    <Button
                      variant="info"
                      href={`/admin/edit-measuretype/${measureType.id}`}
                    >
                      Edit
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() =>
                        DeleteComponent(measureType.id, measureType.Name)
                      }
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginationReact
            activePage={activePage}
            itemsCountPerPage={elementsPerPage}
            totalItemsCount={data.length}
            pageRangeDisplayed={lnNumberTotalPages}
            onChange={(e) => handlePageChange(e)}
          />
        </>
      );
      break;
    case "metrictype":
      body = (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>id</th>
                <th>Name</th>
                <th>Description</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentElements.map((metricType) => (
                <tr key={metricType.id}>
                  <td>{metricType.id}</td>
                  <td>{metricType.Name}</td>
                  <td>{metricType.Description}</td>
                  <td>
                    <Button
                      variant="info"
                      href={`/admin/edit-metrictype/${metricType.id}`}
                    >
                      Edit
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() =>
                        DeleteComponent(metricType.id, metricType.Name)
                      }
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginationReact
            activePage={activePage}
            itemsCountPerPage={elementsPerPage}
            totalItemsCount={data.length}
            pageRangeDisplayed={lnNumberTotalPages}
            onChange={(e) => handlePageChange(e)}
          />
        </>
      );
      break;
    case "producttypes":
      body = (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>id</th>
                <th>Name</th>
                <th>Description</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentElements.map((productType) => (
                <tr key={productType.id}>
                  <td>{productType.id}</td>
                  <td>{productType.Name}</td>
                  <td>{productType.Description}</td>
                  <td>
                    <Button
                      variant="info"
                      href={`/admin/edit-producttype/${productType.id}`}
                    >
                      Edit
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() =>
                        DeleteComponent(productType.id, productType.Name)
                      }
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <PaginationReact
            activePage={activePage}
            itemsCountPerPage={elementsPerPage}
            totalItemsCount={data.length}
            pageRangeDisplayed={lnNumberTotalPages}
            onChange={(e) => handlePageChange(e)}
          />
        </>
      );
      break;
    case "users":
      body = (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>id</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>subscription By Admin</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentElements.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.Name}</td>
                  <td>{user.LastName}</td>
                  <td>{user.Email}</td>
                  <td>{user.UserRoles[0] ? user.UserRoles[0].RoleId == 1 ? "admin" : user.Permission ? "Subscription added" : "Unsubscribed" : user.Permission ? "Subscription added" : "Unsubscribed" }</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() =>
                        DeleteComponent(user.id, user.Name, user.LastName)
                      }
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginationReact
            activePage={activePage}
            itemsCountPerPage={elementsPerPage}
            totalItemsCount={data.length}
            pageRangeDisplayed={lnNumberTotalPages}
            onChange={(e) => handlePageChange(e)}
          />
        </>
      );
      break;
    default:
      break;
  }
  return body;
}

export default SeeComponent;
