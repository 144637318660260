import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import SideBar from "../../../components/SideBar/SideBar";
import { authHeader } from "../../../services/AuthHeader";

const EditProductType = () => {
  let { id } = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const history = useHistory();

  const handleUpdatePrductTypes = (e) => {
    const data = {
      Name: name,
      Description: description,
    };
    e.preventDefault();
    debugger;
    axios
      .put(`${process.env.REACT_APP_API_URL}/productTypes/${id}`, data, {
        headers: authHeader(),
      })
      .then((res) => {
        debugger;
        Swal.fire({
          title: "Success!",
          text: res.data.message,
          icon: "success",
          confirmButtonText: "Ok",
        });
        history.push("/admin/see-producttypes");
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          confirmButtonText: "Try Again",
        });
      });
  };

  return (
    <div className="AppAdmin">
      <SideBar></SideBar>
      <div className="users-title">
        <p>Edit Product Type</p>
      </div>
      <div className="input-product-container">
        <Form>
          <Form.Group controlId="formGroupName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a new product type name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formGroupDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a new description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>

          <div className="product-submit">
            <a href="/admin/see-producttypes">
              <Button size="lg" type="submit" onClick={handleUpdatePrductTypes}>
                <p>Edit</p>
              </Button>
            </a>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EditProductType;
