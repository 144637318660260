import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import SideBar from "../../../components/SideBar/SideBar";
import { authHeader } from "../../../services/AuthHeader";

const EditCompanies = () => {
  let { id } = useParams();
  // const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const result = await axios(`${REACT_APP_API_URL}/companies/`);
  //     setData(result.data);
  //   };
  //   fetchData();
  // }, []);

  const history = useHistory();

  // Esta funcion crea una actualizacion dependiendo del id
  // seleccionado en la tabla de companies y tiene que estar
  // logueado para poder mandar este request.

  const handleUpdateCompanies = (e) => {
    const data = {
      Name: name,
      Description: description,
    };
    e.preventDefault();
    debugger;
    axios
      .put(`${process.env.REACT_APP_API_URL}/companies/${id}`, data, {
        headers: authHeader(),
      })
      .then((res) => {
        debugger;
        Swal.fire({
          title: "Success!",
          text: res.data.message,
          icon: "success",
          confirmButtonText: "Ok",
        });
        history.push("/admin/see-companies");
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          confirmButtonText: "Try Again",
        });
      });
  };

  return (
    <div className="AppAdmin">
      <SideBar></SideBar>
      <div className="users-title">
        <p>Edit Company</p>
      </div>
      <div className="input-product-container">
        <Form>
          <Form.Group controlId="formGroupName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a new company name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formGroupDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a new description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>

          <div className="product-submit">
            <a href="/admin/see-companies">
              <Button size="lg" type="submit" onClick={handleUpdateCompanies}>
                <p>Edit</p>
              </Button>
            </a>
          </div>
        </Form>
      </div>
    </div>
    // <div className="container p-3">
    //   <div style={{ maxWidth: 350 }}>
    //     <div classNames="form-group">
    //       <label htmlFor="name">Name</label>
    //       <input
    //         type="text"
    //         className="form-control"
    //         id="name"
    //         placeholder={name}
    //         value={name}
    //         onChange={(e) => setName(e.target.value)}
    //       />
    //     </div>
    //     <div classNames="form-group">
    //       <label htmlFor="job" className="mt-2">
    //         Job
    //       </label>
    //       <input
    //         type="text"
    //         className="form-control"
    //         id="description"
    //         placeholder="Enter Description"
    //         value={description}
    //         onChange={(e) => setDescription(e.target.value)}
    //       />
    //     </div>
    //   </div>
    // </div>
  );
};

export default EditCompanies;
