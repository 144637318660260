import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SideBar.css";
import { SidebarData } from "./SideBarData";
import Button from "react-bootstrap/Button";

function SideBar() {
  return (
    <div>
      <div className="sidenav">
        <div className="admin-title">
          <span className="admin-title-font">CatheterApp</span>
        </div>
        {SidebarData.map((item, index) => {
          return (
            <Button href={item.link} key={index} variant="sidebar-button">
              {item.icon}
              <span className="space-sidebar"> </span>
              {item.text}
            </Button>
          );
        })}
      </div>
    </div>
  );
}

export default SideBar;
