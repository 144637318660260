import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { NavBarIfLoggedIn } from "../../middleware/IfLoggedIn";
import background from "../models/background-2.jpg";
import "../LegalPages/LegalPages.css";
import { authHeader } from "../../services/AuthHeader";
import Footer from "../../components/Footer/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { useHistory  } from "react-router-dom";

const { REACT_APP_API_URL } = process.env;

const Profile = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [subscriptionC, setSubscriptionC] = useState(false);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  const cancel_paypal = async() => { 
    Swal.fire({
      title: 'Are you sure?',
      text: "Your subscription will be canceled!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel subscription!'
    }).then( async (result) => {
      if (result.isConfirmed) {
        const result = await axios.post(`${process.env.REACT_APP_API_URL}/users/cancel-paypal`, { headers: authHeader() });
        if(result.data.status){
          Toast.fire({
            icon: 'success',
            title: "Subscription successfully canceled, this subscription ends on" + result.data.time
          });
          setSubscriptionC(false);
        }
      }
    });
  };

  useEffect(() => {
    const statusSession = async () => {
      await axios(`${REACT_APP_API_URL}/users/session`, { headers: authHeader() }).catch((e) => {
        if(e.response.status === 503){
          localStorage.removeItem('authorization');
          Toast.fire({
            icon: 'danger',
            title: "The current session expired"
          });
          history.push('/login');
        }
      }); 
    };
    statusSession();
    
    const fetchData = async () => {
      await axios(`${REACT_APP_API_URL}/users/find`, {
        headers: authHeader()
      }).then((res) => {
        setData(res.data);
      }).catch((e) => {
        if(e.response.status === 503){
          localStorage.removeItem('authorization');
          Toast.fire({
            icon: 'danger',
            title: "The current session expired"
          });
          history.push('/login');
        }
      }); 
    };
    fetchData();

    const fetchDataGeneral = async () => {
      const result = await axios.post(`${process.env.REACT_APP_API_URL}/users/subscription`, { headers: authHeader(), });
      setSubscriptionC(result.data.status_subscriptions_status);
    };
    fetchDataGeneral();
  }, []);

  return (
    <>
      <NavBarIfLoggedIn />
      <div className="page-title">
        <img src={background} alt="Catheter background"></img>
        <p>Profile</p>
      </div>
      <div className="pageContainer">
        <div className="pageTextContainer">
          <div className="title-billing">
            <h1>Your Profile</h1>
          </div>
          <div className="hl-details"></div>
          <div className="input-containers-details">
            <div className="name-input-container">
              <div className="name-input-div">
                <Form.Group controlId="formGroupName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" defaultValue={data.Name} required />
                </Form.Group>
                <Form.Group controlId="formGroupLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" defaultValue={data.LastName} required />
                </Form.Group>
              </div>
              <div className="rest-input-details">
                <Form.Group controlId="formGroupCompany">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" defaultValue={data.Email} required />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="profileName" style={{ display:"block" }}>
            <div className="profile-user">
                <div className="alt-img">
                    <img src={'https://www.nicepng.com/png/full/202-2022264_usuario-annimo-usuario-annimo-user-icon-png-transparent.png'} className="img-user" alt="Catheter background"></img>
                </div> 
                <p className="mt-2">
                    Welcome <b>{data.Name} {data.LastName}</b>
                </p>
            </div>
            <div style={{ textAlign:"center", marginTop: "20px", marginBottom: "20px" }}>
            {subscriptionC ? 
              <Button variant="danger" size="md" active onClick={() => { cancel_paypal(); }}>
                cancel subscription
              </Button> 
              :
              ""
            }
            </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
