import React from "react";
import "./AdminLogIn.css";
import AdminLoginForm from "../../components/AdminLoginForm/AdminLoginForm";

const AdminLogIn = () => {
  return (
    <body>
      <div className="log-in-container-admin">
        <div className="admin-log-in-title">
          <h1>CatheterApp admin</h1>
        </div>
        <div className="admin-log-in-container">
          <AdminLoginForm />
        </div>
      </div>
    </body>
  );
};

export default AdminLogIn;
