import React, { useState } from "react";
import "../AdminMenus/AdminLogIn.css";
import "../FinalizePayPage/FinalizePayPage.css";
import Footer from "../../components/Footer/Footer";
import { useStickyState } from "../../middleware/useStickyState";
import background from "../models/background-2.jpg";
import { NavBarIfLoggedIn } from "../../middleware/IfLoggedIn";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./loginform.css";
import axios from "axios";
import Swal from "sweetalert2";


const LoginSubscriptionSimple = () => {
  let history = useHistory();
  const [name, setname] = useState("");
  const [lastname, setlastname] = useState("");
  const [company, setcompany] = useState("");
  const [country, setcountry] = useState("");
  const [direction, setdirection] = useState("");
  const [city, setcity] = useState("");
  const [department, setdepartment] = useState("");
  const [postalcode, setpostalcode] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [repassword, setrepassword] = useState("");
  const [prices, setprices] = useState("45.35");

  const [validname, setvalidname] = useState("");
  const [validlastname, setvalidlastname] = useState("");
  const [validcompany, setvalidcompany] = useState("");
  const [validcountry, setvalidcountry] = useState("");
  const [validemail, setvalidemail] = useState("");
  const [validpassword, setvalidpassword] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  
  const PostSubscription = (e) => {
    setvalidname("");
    setvalidlastname("");
    setvalidcompany("");
    setvalidcountry("");
    setvalidemail("");
    setvalidpassword("");

    if(password !== repassword) {
      setvalidpassword(<p className="error-validation">The password does not match</p>);
    }
    if(password.length < 7) {
      setvalidpassword(<p className="error-validation">The password must be greater than 6 characters</p>);
    }
    if(name.length <= 0){
      setvalidname(<p className="error-validation">Name required</p>);
    }
    if(lastname.length <= 0){
      setvalidlastname(<p className="error-validation">Last Name required</p>);
    }
    if(company.length <= 0){
      setvalidcompany(<p className="error-validation">Company required</p>);
    }
    if(country.length <= 0){
      setvalidcountry(<p className="error-validation">Country required</p>);
    }
    if(email.length <= 0){
      setvalidemail(<p className="error-validation">Email required</p>);
    }

    if(password !== repassword || password.length < 7 || name.length <= 0 || lastname.length <= 0 || company.length <= 0 || country.length <= 0 || email.length <= 0){
      return;
    }

    const data = {
      name: name,
      lastname: lastname,
      company: company,
      country: country,
      direction: direction,
      city: city,
      department: department,
      postalcode: postalcode,
      phone: phone,
      email: email,
      password: password,
      prices: prices
    };

    axios.post(`${process.env.REACT_APP_API_URL}/users/sing-up-subscription`, data).then((res) => {
      if(res.status == 200) {
        Swal.fire({
          icon: 'warning',
          title: 'Pending Account Activation',
          text: 'We have sent you an email to verify the account. Please, confirm your account before trying to login to CatheterBuddy.com'
        }).then((result) => {
          if (result.isConfirmed) {
            history.push('/')
          }
        })
      }
    }).catch((error) => {
        if (error.response.status === 400) {
          Toast.fire({
            icon: 'warning',
            title: error.response ? error.response.data.message : '',
          });
        } else if (error.response.status === 500) {
          Swal.fire({
            title: "Error!",
            text: error.response ? error.response.data.message : '',
            icon: "error",
            confirmButtonText: "Try Again",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: error,
            icon: "error",
            confirmButtonText: "Try Again",
          });
        }
    });
  }

  return (
    <body>
      <NavBarIfLoggedIn></NavBarIfLoggedIn>
      <div className="page-title">
        <img src={background} alt="Catheter background"></img>
        <p>Register Account</p>
      </div>
      <div className="page-container">
        <div className="admin-log-in-container">
          <div className="title-billing">
            <h1>User Details</h1>
          </div>
          <div className="hl-details" style={{ marginTop: "5%" }}></div>
          <div>
            <div className="row mt-4">
              <div className="col-lg-6 mb-4">
                <label className="label">Name</label>
                <input className={"form-control"} type="text" name="name" onChange={(e) => {   setname(e.target.value); }} required/>
                {validname}
              </div>
              <div className="col-lg-6 mb-4">
                <label className="label">Last Name</label>
                <input className="form-control" type="text" name="last_name" onChange={(e) => {   setlastname(e.target.value); }} required/>
                {validlastname}
              </div>
              <div className="col-lg-12 mb-4">
                <label className="label">Company</label>
                <input className="form-control" type="text" name="company" onChange={(e) => {   setcompany(e.target.value); }} required/>
                {validcompany}
              </div>
              <div className="col-lg-12 mb-4">
                <label className="label">Country</label>
                <input className="form-control" type="text" name="country" onChange={(e) => {   setcountry(e.target.value); }} required/>
                {validcountry}
              </div>
              <div className="col-lg-12 mb-4">
                <label className="label">Direction</label>
                <input className="form-control" type="text" name="direction" onChange={(e) => {   setdirection(e.target.value); }} required/>
              </div>
              <div className="col-lg-12 mb-4">
                <label className="label">City</label>
                <input className="form-control" type="text" name="city" onChange={(e) => {   setcity(e.target.value); }} required/>
              </div>
              <div className="col-lg-12 mb-4">
                <label className="label">Department</label>
                <input className="form-control" type="text" name="department" onChange={(e) => {   setdepartment(e.target.value); }} required/>
              </div>
              <div className="col-lg-12 mb-4">
                <label className="label">Postal Code</label>
                <input className="form-control" type="text" name="postal_code" onChange={(e) => {   setpostalcode(e.target.value); }} required/>
              </div>
              <div className="col-lg-12 mb-4">
                <label className="label">Phone</label>
                <input className="form-control" type="text" name="phone" onChange={(e) => {   setphone(e.target.value); }} required/>
              </div>
              <div className="col-lg-12 mb-4">
                <label className="label">Email</label>
                <input className="form-control" type="email" name="email" onChange={(e) => {   setemail(e.target.value); }} required/>
                {validemail}
              </div>
              <div className="col-lg-6 mb-4">
                <label className="label">Password</label>
                <input className="form-control" type="password" name="password" onChange={(e) => {   setpassword(e.target.value); }} required/>
                {validpassword}
              </div>
              <div className="col-lg-6 mb-4">
                <label className="label">Confirm Password</label>
                <input className="form-control" type="password" name="repassword" onChange={(e) => {   setrepassword(e.target.value); }} required/>
              </div>
              <div className="col-lg-6 mb-4">
                <Button onClick={PostSubscription} variant="success">
                  Register
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </body>
  );
};

export default LoginSubscriptionSimple;
