import React, { useState, useEffect } from "react";
import PersonIcon from "@material-ui/icons/Person";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
// import CloseIcon from "@material-ui/icons/Close";
import logo from "../../pages/models/logocatheterbuddybeta.png";
import { FaBars } from "react-icons/fa";
import { OptionsMenu } from "./OptionsMenu/OptionsMenu";
import { authHeader } from "../../services/AuthHeader";
import { useHistory } from 'react-router-dom';

import axios from 'axios';

const NavBar = () => {
  let history = useHistory();
  const [optionsMenu, setOptionsMenu] = useState(false);
  
/*
  useEffect(() => {
    //const agree = localStorage.getItem('agree');
    const _jwt =  JSON.parse((atob(localStorage.getItem('authorization').split(".")[1])));
    console.log("TRAZA", _jwt);
    
    const _exp = _jwt.exp || 0;
    const _date = Math.floor(new Date().getTime() / 1000);
    console.log("TRAZA", _exp, _date);

    if(_date > _exp) {
      localStorage.removeItem('authorization');
      history.push('/');
    }

    const _eula = _jwt.Eula;
    console.log(_eula);
    if (_eula) {
      setEula(_eula);      
    } else {
      localStorage.removeItem('authorization');
      history.push('/');
    }
    console.log("TRAZA", _eula);    
  },[])

  const storeAgree = async () => {
    console.log(eula);
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/eula`, {
        headers: authHeader(),
      }).then(req => {
        setEula(req.status == 200 ? req.data.Eula : false);
      }).catch(err => console.log(err))
  }
*/
  return (
    <>
      <div className="navbar" style={{ justifyContent: "space-evenly" }}>
        <div>
          <a href="/" className="Hover">
            <img
              src={logo}
              alt="logocatheterbuddy"
              style={{ marginTop: "7.5px" }}
              className="img-logo-catheterbuddy"
            />
          </a>
        </div>
        <div className="navbarOptions">
          <a href="/feedback" className="navbarLink Hover">
            <ChatBubbleIcon className="navbar-Icon"/>
            <span className="navbarText">Feedback</span>
          </a>
          <a href="/profile" className="navbarLink Hover">
            <PersonIcon className="navbar-Icon"/>
            <span className="navbarText">My profile</span>
          </a>
          <button
            className="faBarsButton"
            type="button"
            onClick={() => setOptionsMenu(!optionsMenu)}
          >
            <FaBars className="faBars Hover" />
          </button>
        </div>
        <div className="faBarsMobile">
          <button
            className="faBarsButton"
            type="button"
            onClick={() => setOptionsMenu(!optionsMenu)}
          >
            <FaBars className="faBars Hover" />
          </button>
        </div>
      </div>
      <OptionsMenu optionsMenu={optionsMenu} isLoggedIn />
    </>
  );
};

export default NavBar;
