import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { NavBarIfLoggedIn } from "../../middleware/IfLoggedIn";
import background from "../models/background-2.jpg";
import Footer from "../../components/Footer/Footer";
import { authHeader } from "../../services/AuthHeader";
import "./ChangePassword.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
const { REACT_APP_API_URL } = process.env;

const Profile = () => {
  let history = useHistory();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [validate, setValidate] = useState("");
  const [data, setData] = useState([]);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      await axios(`${REACT_APP_API_URL}/users/find`, {
        headers: authHeader()
      }).then((res) => {
        setData(res.data);
      }).catch((e) => {
        if(e.response.status === 503){
          localStorage.removeItem('authorization');
          Toast.fire({
            icon: 'danger',
            title: "The current session expired"
          });
          history.push('/login');
        }
      }); 
    };
    fetchData();
  }, []);

  const ChangePassword = (e) => {
    const data = {
        Password: password,
        NewPassword: newPassword
    };
    e.preventDefault();
    if(password.length < 6){
        setValidate(
            <p className="password-validation">You must enter your current password</p>
        );
    }else if(newPassword.length < 6){
        setValidate(
            <p className="password-validation">You must enter your new password</p>
        );
    }else if (reNewPassword !== newPassword) {
      debugger;
      setValidate(
        <p className="password-validation">The password does not match</p>
      );
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/users/change-password`, data, {
          headers: authHeader()
        })
        .then((res) => {
          setPassword("");
          setNewPassword("");
          Swal.fire({
            title: "Success!",
            text: res.data.message,
            icon: "success",
            confirmButtonText: "Ok!",
          });
          history.push("/profile");
        })
        .catch((error) => {
          debugger;
          Swal.fire({
            title: "Error!",
            text: error.response ? error.response.data.message : '',
            icon: "error",
            confirmButtonText: "Try Again",
          });
        });
    }
  };


  return (
    <>
      <NavBarIfLoggedIn />
      <div className="page-title">
        <img src={background} alt="Catheter background"></img>
        <p>Edit Password</p>
      </div>
      <div className="pageContainer">
        <div className="pageTextContainer">
          <div className="title-billing">
            <h1>Edit Your Password</h1>
          </div>
          <div className="hl-details"></div>
          <form>
          <div className="input-containers-details">
            <div className="name-input-container">
              <div className="rest-input-details">
                <Form.Group controlId="formGroupCompany">
                  <Form.Label>Your current password</Form.Label>
                  <input 
                    type="text" 
                    name={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }} 
                    required 
                  />
                </Form.Group>
              </div>
              <div className="rest-input-details">
                <Form.Group controlId="formGroupCompany">
                  <Form.Label>Your new password</Form.Label>
                  <input
                    type="text" 
                    name={newPassword} 
                    onChange={(e) => {
                        setNewPassword(e.target.value);
                    }} 
                    required 
                  />
                </Form.Group>
              </div>
              <div className="rest-input-details">
                <Form.Group controlId="formGroupCompany">
                  <Form.Label>Repeat new password</Form.Label>
                  <input 
                    type="text" 
                    name={reNewPassword} 
                    onChange={(e) => {
                        setReNewPassword(e.target.value);
                    }} 
                    required  
                  />
                </Form.Group>
              </div>
              {ChangePassword ? validate : ""}
            </div>
          </div>
          <Button style={{ marginLeft: "25px", background: "#199e53" }} onClick={ChangePassword}>Save password</Button>
          </form>
        </div>
        <div className="profileName">
            <div className="profile-user">
                <div className="alt-img">
                    <img src={'https://www.nicepng.com/png/full/202-2022264_usuario-annimo-usuario-annimo-user-icon-png-transparent.png'} className="img-user" alt="Catheter background"></img>
                </div> 
                <p className="mt-2">
                  Welcome <b>{data.Name} {data.LastName}</b>
                </p>
            </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
