import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { optionsMenuData, optionsMenuNotLogged } from "./OptionsMenuData";
import { useStickyState } from "../../../middleware/useStickyState";
import { useHistory } from "react-router";

export const OptionsMenu = ({ optionsMenu, isLoggedIn }) => {
  let history = useHistory();
  const [token, setToken] = useStickyState("authorization", "");

  const handleSignOut = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`${process.env.REACT_APP_API_URL}/users/logout`)
          .then((res) => {
            setToken(res.data.token);
            history.push(`/`);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              Swal.fire({
                title: "Error!",
                text: error.response ? error.response.data.message : '',
                icon: "error",
                confirmButtonText: "Try Again",
              });
            } else if (error.response.status === 500) {
              Swal.fire({
                title: "Error!",
                text: error.response ? error.response.data.message : '',
                icon: "error",
                confirmButtonText: "Try Again",
              });
            } else {
              Swal.fire({
                title: "Error!",
                text: error,
                icon: "error",
                confirmButtonText: "Try Again",
              });
            }
          });
      }
    });
  };

  return (
    <>
      {optionsMenu === true ? (
        <div className="optionsMenuContainer">
          {isLoggedIn ? (
            <>
              {optionsMenuData.map((link, index) => (
                <a
                  key={index}
                  href={link.link}
                  style={{ textDecoration: "none", color: "#616161" }}
                >
                  <p className={link.class}>{link.title}</p>
                </a>
              ))}
              <button
                type="button"
                className="signOutButton"
                onClick={handleSignOut}
              >
                <p className="Hover">Sign out</p>
              </button>
            </>
          ) : (
            optionsMenuNotLogged.map((link, index) => (
              <a
                key={index}
                href={link.link}
                style={{ textDecoration: "none", color: "#616161" }}
              >
                <p className={link.class}>{link.title}</p>
              </a>
            ))
          )}
        </div>
      ) : null}
    </>
  );
};
