import React, { useEffect, useState } from "react";
import SideBar from "../../../components/SideBar/SideBar";
import "./AddElement.css";
import { Form, Button } from "react-bootstrap/";
import axios from "axios";
import Swal from "sweetalert2";
import { authHeader } from "../../../services/AuthHeader";

const AddUser = () => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [permission, setPermission] = useState(false);

  const [country, setcountry] = useState("");
  const [direction, setdirection] = useState("");
  const [city, setcity] = useState("");
  const [department, setdepartment] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [phone, setphone] = useState("");

  const InputData = [
    {
      label: "Name",
      type: "text",
      placeholder: "Enter a Name",
      value: name,
      onChange: setName,
    },
    {
      label: "Last Name",
      type: "text",
      placeholder: "Enter a Last Name",
      value: lastName,
      onChange: setLastName,
    },
    {
      label: "Country",
      type: "text",
      placeholder: "Enter a Country",
      value: country,
      onChange: setcountry,
    },
    {
      label: "Direction",
      type: "text",
      placeholder: "Enter a Direction",
      value: direction,
      onChange: setdirection,
    },
    {
      label: "City",
      type: "text",
      placeholder: "Enter a City",
      value: city,
      onChange: setcity,
    },
    {
      label: "Department",
      type: "text",
      placeholder: "Enter a Department",
      value: department,
      onChange: setdepartment,
    },
    {
      label: "Postal Code",
      type: "text",
      placeholder: "Enter a Postal Code",
      value: postalCode,
      onChange: setpostalCode,
    },
    {
      label: "Phone",
      type: "text",
      placeholder: "Enter a Phone",
      value: phone,
      onChange: setphone,
    },

    {
      label: "Email",
      type: "email",
      placeholder: "Enter an Email",
      value: email,
      onChange: setEmail,
    },
    {
      label: "Password",
      type: "password",
      placeholder: "Enter a Password",
      value: password,
      onChange: setPassword,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const roleResult = await axios(`${process.env.REACT_APP_API_URL}/roles/`);
      setRoleList(roleResult.data);
    };
    fetchData();
  }, []);

  const handleSubmit = (e) => {
    const data = {
      Name: name,
      LastName: lastName,
      Email: email,
      Password: password,
      RoleId: roleId,
      Permission: permission,
      Country: country,
      Direction: direction,
      City: city,
      Department: department,
      PostalCode: postalCode,
      Phone: phone
    };
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API_URL}/users/add-user`, data, {
        headers: authHeader(),
      })
      .then((res) => {
        Swal.fire({
          title: "Success!",
          text: `${res.data.Name} has been added!`,
          icon: "success",
          confirmButtonText: "Ok",
        });
        setName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setcountry("");
        setdirection("");
        setcity("");
        setdepartment("");
        setpostalCode("");
        setphone("");
        setPermission(false);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.response ? error.response.data.message : error,
          icon: "error",
          confirmButtonText: "Try Again",
        });
      });
  };

  return (
    <div className="AppAdmin">
      <SideBar></SideBar>
      <div className="users-title">
        <p>Add User</p>
      </div>
      <div className="input-product-container">
        <Form>
          {InputData.map((input, index) => (
            <Form.Group key={index} controlId="formGroupName">
              <Form.Label>{input.label}</Form.Label>
              <Form.Control
                type={input.type}
                placeholder={input.placeholder}
                value={input.value}
                onChange={(e) => input.onChange(e.target.value)}
                required
              />
            </Form.Group>
          ))}
          <Form.Group controlId="formGroupRole">
            <Form.Label>Company select</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                setRoleId(e.target.value);
              }}
            >
              <option>Select a Role</option>
              {roleList.map((role) => (
                <option key={role.id} value={role.id}>{role.Role}</option>
              ))}
            </Form.Control>
          </Form.Group>
          {roleId == 2 ?
            <>
              <Form.Group controlId="formGroupRole">
                <Form.Label>Add subscription</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setPermission(e.target.value);
                  }}
                >
                  <option value="0">False</option>
                  <option value="1">True</option>
                </Form.Control>
              </Form.Group>
            </>
            :
            ""
          }
          <div className="product-submit">
            <a href="/admin/see-metrictype">
              <Button size="lg" type="submit" onClick={handleSubmit}>
                <p>Add</p>
              </Button>
            </a>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddUser;
