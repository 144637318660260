import React, {useEffect} from "react";
import { useHistory } from 'react-router';
import "./Homepage.css";
import Footer from "../../components/Footer/Footer";
import { NavBarIfLoggedIn } from "../../middleware/IfLoggedIn";
import background from "../models/background.png";
import video from "../models/video-temporal.png";
import { homepageData, secondHomepageData } from "./HomepageData";

const Homepage = () => {
  const history = useHistory();
  useEffect(() => {
    document.title = "CatheterBuddy | Medical Device Compatibility Checker";
    document.getElementById('description_meta_utf8').setAttribute("content", " The advanced on-line Medical Device Compatibility Checker available for Healthcare Professionals.");
  }, []);
  return (
    <>
      <NavBarIfLoggedIn></NavBarIfLoggedIn>
      <div className="homepage-background-container">
        <div>
          <img src={background} alt="" className="homepage-img-backgrouns"/>
          <div className="homepage-title">
            The advanced on-line Medical Device Compatibility <br/> Checker available for Healthcare Professionals
          </div>
          <div className="homepage-subtitle">
              <div className="button-subtitle">
                Demonstration Video <i className="arrow down"></i>
              </div>
          </div>
          <div className="homepage-video-div">
            <div className="homepage-video-item">
              <video controls autoPlay muted loop>
                <source src="/video/video1.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="homepage-video-item">
              <video controls autoPlay muted loop>
                <source src="/video/video2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div className="homepage-feature-container">
          <div className="homepage-feature-title">Discover the CatheterBuddy platform</div>
          <div className="features-container">
            {homepageData.map((feature, index) => (
              <div key={`first-${index}`} className="homepage-features-container">
                <div className="homepage-features">
                  <img src={feature.img} alt="" style={{ borderRadius: "10px" }} onClick={feature.link ? () => history.push(feature.link) : () => {}}/>
                </div>
                <div className="homepage-features-text">
                  <div>
                    <div className="features-title">{feature.title}</div>
                    <div className="features-text">{feature.text} </div>
                  </div>
                </div>
              </div>
            ))}
            {secondHomepageData.map((feature, index) => (
              <div key={`second-${index}`} className="homepage-features-container">
                <div className="homepage-features">
                  <img src={feature.img} alt="" />
                </div>
                <div className="homepage-features-text">
                  <div>
                    <div className="features-title">{feature.title}</div>
                    <div className="features-text">{feature.text} </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Homepage;
