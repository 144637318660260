import React from "react";
import "./SeeElement.css";
import SideBar from "../../../components/SideBar/SideBar";
import Button from "react-bootstrap/Button";
import SeeComponent from "../../../components/SeeComponent/SeeComponent";
import { useParams } from "react-router";

function SeePages() {
  let { models } = useParams();

  return (
    <div className="AppAdmin">
      <SideBar></SideBar>
      <div className="users-title">
        <p>{models}</p>
      </div>
      <div className="users-add-button">
        <a href={`/admin/add-${models}`}>
          <Button size="lg">
            <p>+</p>
          </Button>
        </a>
      </div>
      <div className="table-Users">
        <SeeComponent />
      </div>
    </div>
  );
}

export default SeePages;
