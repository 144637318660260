import React, { useState } from "react";
import "./NavBar.css";
import { Button } from "react-bootstrap";
import logo from "../../pages/models/logocatheterbuddybeta.png";
import { FaBars } from "react-icons/fa";
import { OptionsMenu } from "./OptionsMenu/OptionsMenu";

const NavBarNoAccount = () => {
  const [optionsMenu, setOptionsMenu] = useState(false);

  return (
    <>
      <div className="navbar" style={{ justifyContent: "space-evenly" }}>
        <a href="/" className="navbarLogo Hover">
          <img
            src={logo}
            alt="logocatheterbuddy"
            style={{ marginTop: "7.5px" }}
            className="img-logo-catheterbuddy"
          />
        </a>
        <div className="navbarOptions">
          <a href="/subscription" className="navbarLink Hover">
            Subscription
          </a>
          <a href="/login">
            <Button type="button" variant="success" className="Hover">
              LOGIN
            </Button>
          </a>
          {/* <button
            className="faBarsButton"
            type="button"
            onClick={() => setOptionsMenu(!optionsMenu)}
          >
            <FaBars className="faBars Hover" />
          </button> */}
        </div>
        <div className="faBarsMobile">
          <button
            className="faBarsButton"
            type="button"
            onClick={() => setOptionsMenu(!optionsMenu)}
          >
            <FaBars className="faBars Hover" />
          </button>
        </div>
      </div>
      <OptionsMenu optionsMenu={optionsMenu} />
    </>
  );
};

export default NavBarNoAccount;
