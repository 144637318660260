import React from "react";
import { useParams } from "react-router";
import "./Figures.css";

const CatheterForm = ({ handleSelectProduct, products, selectedProduct, visibleinput, selectedValue }) => {

  const productAText = (className) => {
    return (
      <div 
        className={className} 
        style={products.productA ? { color: "#000" } : {}} 
        onClick={() => {
          handleSelectProduct("a");
          visibleinput(true); 
          selectedValue(products.productA || "");
        }}
      >
        {products.productA ? (
          <h3>
            <span>
              Select to Enter Product <strong>A</strong>
            </span>
          </h3>
        ) : (
          <>
            {" "}
            <h3>
              <span>
                Select to Enter Product <strong>A</strong>
              </span>
            </h3>
          </>
        )}
      </div>
    );
  };

  const productBText = (className) => {
    return (
      <div
        className={className}
        onClick={() => {
          handleSelectProduct("b");
          visibleinput(true);
          {products.productB ? selectedValue(products.productB): selectedValue("") }
        }}
      >
        {products.productB ? (
          <p style={{ marginTop: "13px" }}>Select to Enter<br/>Product B</p>
        ) : (
          <>
            {" "}
            <p style={{ marginTop: "13px" }}>Select to Enter<br/>Product B</p>
          </>
        )}
      </div>
    );
  };

  const productCText = (className) => {
    return (
      <div
        className={className}
        onClick={() => {
          handleSelectProduct("c");
          visibleinput(true);
          {products.productC ? selectedValue(products.productC): selectedValue("") }
        }}
      >
        {products.productC ? (
          <p style={{ marginTop: "13px" }}>Select to Enter Product C</p>
        ) : (
          <>
            {" "}
            <p style={{ marginTop: "13px" }}>Select to Enter Product C</p>
          </>
        )}
      </div>
    );
  };

  const productDText = (className) => {
    return (
      <div
        className={className}
        onClick={() => {
          handleSelectProduct("d");
          visibleinput(true);
          {products.productD ? selectedValue(products.productD): selectedValue("") }
        }}
      >
        {products.productD ? (
          <p style={{ marginTop: "13px" }}>Select to Enter Product D</p>
        ) : (
          <>
            {" "}
            <p style={{ marginTop: "13px" }}>Select to Enter Product D</p>
          </>
        )}
      </div>
    );
  };

  let { catheter } = useParams();
  let body = <></>;

  switch (catheter) {
    case "a":
      body = (
        <div className={`catheter-design-one catheter-big ${ selectedProduct === "a" ? `selected_parent` : `` }`}>
          {productAText(`text-inside-catheter-a-one Hover`)}
          <div className={`catheter-design-inside-one ${ selectedProduct === "b" ? `selected` : `` }`}>
            {productBText(`text-inside-catheter-a-two Hover`)}
          </div>
        </div>
      );
      break;
    case "b":
      body = (
        <div className={`catheter-design-two catheter-big ${ selectedProduct === "a" ? `selected_parent` : `` }`}>
          {productAText(`text-inside-catheter-b-one Hover`)}
          <div
            className={`catheter-design-inside-two-one ${
              selectedProduct === "b" ? `selected` : ``
            }`}
          >
            {productBText(`text-inside-catheter-b-two Hover`)}
          </div>
          <div
            className={`catheter-design-inside-two-two ${
              selectedProduct === "c" ? `selected` : ``
            }`}
          >
            {productCText(`text-inside-catheter-b-three Hover`)}
          </div>
        </div>
      );
      break;
    case "c":
      body = (
        <div className={`catheter-design-three catheter-big ${ selectedProduct === "a" ? `selected_parent` : `` }`}>
          {productAText(`text-inside-catheter-c-one Hover`)}
          <div
            className={`catheter-design-inside-three-one ${
              selectedProduct === "b" ? `selected` : ``
            }`}
          >
            {productBText(`text-inside-catheter-c-two Hover`)}
          </div>
          <div
            className={`catheter-design-inside-three-two ${
              selectedProduct === "c" ? `selected` : ``
            }`}
          >
            {productCText(`text-inside-catheter-c-three Hover`)}
          </div>
          <div
            className={`catheter-design-inside-three-three ${
              selectedProduct === "d" ? `selected` : ``
            }`}
          >
            {" "}
            {productDText(`text-inside-catheter-c-four Hover`)}
          </div>
        </div>
      );
      break;
    default:
      break;
  }
  return body;
};

export default CatheterForm;
