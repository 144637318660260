import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "./LegalPages.css";
import Footer from "../../components/Footer/Footer";
import { NavBarIfLoggedIn } from "../../middleware/IfLoggedIn";
import { authHeader } from "../../services/AuthHeader";
import background from "../models/background-2.jpg";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const LegalPages = () => {
  let history = useHistory();
  let { forms } = useParams();
  const [title, setTitle] = useState(<></>);
  const [text, setText] = useState(<></>);

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [validate, setValidate] = useState("");
  const sendContact = (e) => {
    const data = {
      Name: name,
      Mobile: mobile,
      Email: email,
      Message: message,
    };
    e.preventDefault();

    if (name.length <= 0 || mobile.length <= 0 || email.length <= 0 || message.length <= 0) {
      debugger;
      setValidate(
        <p className="password-validation">All fields are required</p>
      );
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/contact`, data, {
          headers: authHeader()
        })
        .then((res) => {
          setMessage("");
          Swal.fire({
            title: "Success!",
            text: res.data.message,
            icon: "success",
            confirmButtonText: "Ok!",
          });
          history.push("/");
        })
        .catch((error) => {
          debugger;
            Swal.fire({ 
              title: "Error!",
              text: error.response ? error.response.data.message : '',
              icon: "error",
              confirmButtonText: "Try Again",
            });
        });
    }
  };

  useEffect(() => {
    switch (forms) {
      case "legal-warning":
        document.title = "Legal Warning | CatheterBuddy | Medical Device Compatibility Checker";
        document.getElementById('description_meta_utf8').setAttribute("content", "These legal warning (legal) apply when you use this website, CatheterBuddy.com (Website)…");
        setTitle(
          <>
            <p>Legal pages</p>
          </>
        );
        setText(
          <>
            <h3>1. Introduction</h3>

            <ul className="styleulalpha">
                <li>These legal warning (legal) apply when you use this website, CatheterBuddy.com (Website).</li>
                <li>You agree to be bound by these Terms which form a binding contractual agreement between you and us,.</li>
                <li>If you don’t agree to these Terms, you must refrain from using the Website.</li>
                <li>We may change these Terms at any time by updating this page of the Website, and your continued use of the Website following such an update will represent an agreement by you to be bound by the Terms as amended.</li>
            </ul>

            <h3>2. Access And Use Of The Website</h3>

            <p>You must only use the Website in accordance with these Terms and any applicable laws, and must ensure that your employees, sub-contractors and any other agents who use or access the Website comply with the Terms and any applicable laws.</p>

            <h3>3. Your Obligations</h3>

            <p>You must not:</p>

            <ul className="styleulalpha">
                <li>copy, mirror, reproduce, translate, adapt, vary, modify, sell, decipher or decompile any part or aspect of the Website without the express consent of Advisible;</li>
                <li>use the Website for any purpose other than the purposes of browsing, selecting or purchasing goods;</li>
                <li>use, or attempt to use, the Website in a manner that is illegal or fraudulent or facilitates illegal or fraudulent activity;</li>
                <li>use, or attempt to use, the Website in a manner that may interfere with, disrupt or create undue burden on the Website or the servers or networks that host the Website;</li>
                <li>use the Website with the assistance of any automated scripting tool or software;</li>
                <li>act in a way that may diminish or adversely impact the reputation of Advisible, including by linking to the Website on any other website; and</li>
                <li>attempt to breach the security of the Website, or otherwise interfere with the normal functions of the Website, including by:</li>
                <ul className="styleusroman">
                    <li>gaining unauthorised access to Website accounts or data;</li>
                    <li>scanning, probing or testing the Website for security vulnerabilities;</li>
                    <li>overloading, flooding, mailbombing, crashing or submitting a virus to the Website; or</li>
                    <li>instigate or participate in a denial-of-service attack against the Website.</li>
                </ul>
            </ul>

            <h3>4. Information On The Website</h3>

            <p>While we make every effort to ensure that the information on the Website is as up-to-date and accurate as possible, you acknowledge and agree that we do not (to the maximum extent permitted by law) guarantee that:</p>

            <ul className="styleulalpha">
                <li>the Website will be free from errors or defects;</li>
                <li>the Website will be accessible at all times;</li>
                <li>messages sent through the Website will be delivered promptly, or delivered at all;</li>
                <li>information you receive or supply through the Website will be secure or confidential; or</li>
                <li>any information provided through the Website is accurate or true.</li>
            </ul>

            <p>We reserve the right to change any information or functionality on the Website by updating the Website at any time without notice, including product descriptions, prices and other Website Content.</p>

            <h3>5. Intellectual Property</h3>

            <ul className="styleulalpha">
                <li>Advisible retains ownership of the Website and all materials on the Website (including text, graphics, logos, design, icons, images, sound and video recordings, pricing, downloads and software) (Website Content) and reserves all rights in any intellectual property rights owned or licensed by it not expressly granted to you.</li>
                <li>You may make a temporary electronic copy of all or part of the Website for the sole purpose of viewing it. You must not otherwise reproduce, transmit, adapt, distribute, sell, modify or publish the Website or any Website Content without prior written consent from Advisible or as permitted by law.</li>
            </ul>

            <h3>6. Links To Other Websites</h3>

            <ul className="styleulalpha">
                <li>The Website may contain links to other websites that are not our responsibility. We have no control over the content of the linked websites and we are not responsible for it.</li>
                <li>Inclusion of any linked website on the Website does not imply our approval or endorsement of the linked website.</li>
            </ul>

            <h3>7. Security</h3>

            <p>Advisible does not accept responsibility for loss or damage to computer systems, mobile phones or other electronic devices arising in connection with use of the Website. You should take your own precautions to ensure that the process that you employ for accessing the Website does not expose you to risk of viruses, malicious computer code or other forms of interference.</p>

            <h3>8. Reporting Misuse</h3>

            <p>If you become aware of misuse of the Website by any person, any errors in the material on the Website or any difficulty in accessing or using the Website, please contact us immediately using the contact details or form provided on our Website.</p>

            <h3>9. Privacy</h3>

            <p>You agree to be bound by our Privacy Policy.</p>

            <h3>10. Liability</h3>

            <p>We make no warranties or representations about this Website or any of its content and will not be responsible to you or any third party for any direct or consequential loss suffered in connection with the use of this Website. To the maximum extent permitted by law, we exclude any liability that may arise due to your use of our Website and/or the information or materials contained on it. You agree to indemnify us for any loss or liability arising out of your use of this Website.</p>

            <h3>11. General</h3>

            <div className="stylediv">
                <h3>11.1 Governing Law And Jurisdiction</h3>

                <p>This agreement is governed by the law applying in Australia. Each party irrevocably submits to the exclusive jurisdiction of the courts of Australia and courts of appeal from them in respect of any proceedings arising out of or in connection with this agreement. Each party irrevocably waives any objection to the venue of any legal process on the basis that the process has been brought in an inconvenient forum.</p>
                
                <h3>11.2 Waiver</h3>

                <p>No party to this agreement may rely on the words or conduct of any other party as a waiver of any right unless the waiver is in writing and signed by the party granting the waiver.</p>
                
                <h3>11.3 Severance</h3>
                
                <p>Any term of this agreement which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. The validity and enforceability of the remainder of this agreement is not limited or otherwise affected.</p>
                
                <h3>11.4 Joint And Several Liability</h3>
                
                <p>An obligation or a liability assumed by, or a right conferred on, two or more persons binds or benefits them jointly and severally.</p>
                
                <h3>11.5 Assignment</h3>
                
                <p>A party cannot assign, novate or otherwise transfer any of its rights or obligations under this agreement without the prior written consent of the other party.</p>
                
                <h3>11.6 Entire Agreement</h3>
                
                <p>This agreement embodies the entire agreement between the parties and supersedes any prior negotiation, conduct, arrangement, understanding or agreement, express or implied, in relation to the subject matter of this agreement.</p>
                
                <h3>11.7 Interpretation</h3>

                <ul className="styleulalpha">
                    <li>(singular and plural) words in the singular includes the plural (and vice versa);</li>
                    <li>(gender) words indicating a gender includes the corresponding words of any other gender;</li>
                    <li>(defined terms) if a word or phrase is given a defined meaning, any other part of speech or grammatical form of that word or phrase has a corresponding meaning;</li>
                    <li>(person) a reference to “person” or “you” includes an individual, the estate of an individual, a corporation, an authority, an association, consortium or joint venture (whether incorporated or unincorporated), a partnership, a trust and any other entity;</li>
                    <li>(party) a reference to a party includes that party’s executors, administrators, successors and permitted assigns, including persons taking by way of novation and, in the case of a trustee, includes any substituted or additional trustee;</li>
                    <li>(this agreement) a reference to a party, clause, paragraph, schedule, exhibit, attachment or annexure is a reference to a party, clause, paragraph, schedule, exhibit, attachment or annexure to or of this agreement, and a reference to this agreement includes all schedules, exhibits, attachments and annexures to it;</li>
                    <li>(document) a reference to a document (including this agreement) is to that document as varied, novated, ratified or replaced from time to time;</li>
                    <li>(headings) headings and words in bold type are for convenience only and do not affect interpretation;</li>
                    <li>(includes) the word “includes” and similar words in any form is not a word of limitation;</li>
                    <li>(adverse interpretation) no provision of this agreement will be interpreted adversely to a party because that party was responsible for the preparation of this agreement or that provision; and</li>
                    <li>(currency) a reference to $, or “dollar”, is to Australian currency, unless otherwise agreed in writing.</li>
                </ul>
            </div>
          </>
        );
        break;
      case "about-us":
        document.title = "About Us | CatheterBuddy | Medical Device Compatibility Checker";
        document.getElementById('description_meta_utf8').setAttribute("content", "Our team have created the Catheter Buddy platform to simplify the usage of compatible medical devices in the clinical setting.");
        setTitle(
          <>
            <p>About us</p>
          </>
        );
        setText(
          <>
            <p>
              Catheter Buddy is the first Online Product Compatibility Checker for healthcare
              professionals. Our team have created the Catheter Buddy platform to simplify the usage of
              compatible medical devices in the clinical setting.
            </p>
            <p>
              Currently we are available for the European and Australian market. By utilising our online
              CatheterBuddy Webb app you will be able to check your medical devices compatibility in a few
              quick clicks on your mobile or computer device. 
            </p>
            <p>
              Our experience and knowledge enable the CatheterBuddy team to give our users the best service
              in medical product compatibility. Start your CatheterBuddy experience here and now.
            </p>
          </>
        );
        break;
      case "features":
        document.title = "Features | CatheterBuddy | Medical Device Compatibility Checker";
        document.getElementById('description_meta_utf8').setAttribute("content", "Simple and intuitive dashboard for a streamlined interactive experience, CatheterBuddy enables you to check your medical devices compatibility…");
        setTitle(
          <>
            <p>Features</p>
          </>
        );
        setText(
          <div className="style-features-list">
            <div><b>Dashboard</b> .-Simple and intuitive dashboard for a streamlined interactive experience</div>
            <div><b>Product Compatibility</b> .-CatheterBuddy enables you to check your medical devices compatibility in a few quick clicks on your mobile or computer device. Click here to start your search</div>
            <div><b>Historical</b> .-Users will have access to their historical searches</div>
            <div><b>Your account</b> .-Here you can find your CatheterBuddy personal details and historial information</div>
            <div><b>Share</b> .-Share your searches via WhatsApp, SMS or email</div>
            <div><b>Notifications</b> .-You will receive optional CatheterBuddy notifications on improvements and updates</div>
          </div>
        );
        break;
      case "faq":
        document.title = "FAQ,s | CatheterBuddy | Medical Device Compatibility Checker";
        document.getElementById('description_meta_utf8').setAttribute("content", "What is Catheter Buddy? It is an advanced online medical device product compatibility checker for healthcare professionals…");
        setTitle(
          <>
            <p>FAQ’s</p>
          </>
        );
        setText(
          <>
            <div className="styleLegal">
              <h3>What is Catheter Buddy?</h3>

              <p>It is an advanced online <span style={{ color: "blue" }}>medical device product compatibility checker</span> for healthcare professionals</p>

              <h3>How can CatheterBuddy help me as a professional?</h3>

              <p>Our main purpose is to speed up the process of <span style={{ color: "blue" }}>checking medical device products compatibility in the Vascular/Interventional
                space</span>, to save you time.</p>
              
              <h3>How much is the subscription cost and what does it include?</h3>

              <p>Our monthly plan costs “to be determined”. CatheterBuddy will give every member:</p>

              <ul>
                <li>Their own personal account to create their profile</li>
                <li>Access to the <span style={{ color: "blue" }}>medical devices</span> comparator and historical searches made by other <span style={{ color: "blue" }}>CatheterBuddy members.</span></li>
                <li><span style={{ color: "blue" }}>The ability to</span> share <span style={{ color: "blue" }}>your</span> searches and results via WhatsApp/text or email with your peers.</li>
                <li><span style={{ color: "blue" }}>Provide feedback direct to the CatheterBuddy team</span></li>
              </ul>
              
              <h3>How can I pay my monthly subscription?</h3>

              <p>There are two ways of paying your monthly subscription in CatheterBuddy: </p>

              <ul>
                <li>PayPal</li>
                <li>Credit or Debit card</li>
              </ul>

              <h3>Can I cancel my subscription?</h3>

              <p>Yes, you can cancel your monthly subscription at any time. You will continue to have access to your CatheterBuddy profile until the end of the subscription period. </p>

              <h3>Where can I find the historial searches?</h3>

              <p>Within your <span style={{ color: "blue" }}>CatheterBuddy</span> profile you will have access to your historical searches. </p>

              <h3>Can I share my search with other people?</h3>

              <p>Absolutely. You can share them via Text, WhatsApp or Email</p>
            </div>
          </>
        );
        break;
      case "contact-us":
        document.title = "Contact Us | CatheterBuddy | Medical Device Compatibility Checker";
        document.getElementById('description_meta_utf8').setAttribute("content", "Our experience and knowledge enable the CatheterBuddy team to give our users the best service in medical product compatibility…");
        setTitle(
          <>
            <p>Contact us</p>
          </>
        );
        break;
      case "terms-of-use":
        document.title = "Terms of Use | CatheterBuddy | Medical Device Compatibility Checker";
        document.getElementById('description_meta_utf8').setAttribute("content", "All information (including but not limited to the property areas, floor size, price, address and general property description) on CatheterBuddy website is provided as a convenience…");
        setTitle(
          <>
            <p>Terms of use</p>
          </>
        );
        setText(
          <>
            <p>All information (including but not limited to the property areas, floor size, price, address and general property description) on CatheterBuddy website is provided as a convenience to you and has been provided to CatheterBuddy by third Parties.</p>

            <p>In placing this information on the website, CatheterBuddy, it subsidiary companies and its directors, officers, employees, agents and related entities have received the information passed on from third parties, CatheterBuddy  has used their best endeavours to ensure the information contained on the website is correct, however, CatheterBuddy  licensors or suppliers (nor their respective directors, affiliates or employees) have not checked the accuracy of the information and do no more than pass it on. They have no belief one way or the other as to its accuracy. they do not accept any liability (direct or indirect) for any injury, loss claim, damage to any incidental or consequential damages, including but not limited to loss of profits or savings, arising out of or in anyway connected with the use of any information, or any error, omission or defect in the information contained on the website.</p>

            <p>Information contained on the website should not be relied upon and you should make your own enquires and seek legal advice with respect to any property on the website or the information about the property contained on the website.</p>

            <p>Prices displayed on the websites are current at the time of issue, but may change at any time and are subject to availability.
            Copyright</p>

            <p>All material available or accessible from this website is copyright material owned by CatheterBuddy and related entities. Apart from fair dealing permitted by the Copyright Act 1968, visitors to the site are granted permission to download and display any of the website material for private purposes.</p>

            <p>For intended use of any copyright material beyond private or research uses, permission must be sought directly from CatheterBuddy or its subsidiary companies. If permission is given, it will be subject to a requirement that the copyright owner's name and interest is acknowledged when reproducing the whole or part of any copyright material.</p>
          </>
        );
        break;
      case "privacy-policy":
        document.title = "Privacy Policy | CatheterBuddy | Medical Device Compatibility Checker";
        document.getElementById('description_meta_utf8').setAttribute("content", "This Privacy Policy applies to information which identifies you, or from which you can reasonably be identified, such as your full name or name and postal address…");
        setTitle(
          <>
            <p>Privacy policy</p>
          </>
        );
        setText(
          <>
            <h3>1. INTRODUCTION</h3>

            <p>This Privacy Policy applies to information which identifies you, or from which you can reasonably be identified, such as your full name or name and postal address ("personal information"). This Policy describes how we handle the personal information we collect when we provide our services. Our services include: home delivery subscription, digital services subscription, advertising, websites, mobile sites, applications ("apps"), and widgets, competitions and customer surveys (collectively, "Company Services").</p>

            <p>This Privacy Policy otherwise applies to information about anyone who interacts with us, including advertisers, subscribers and people who use Company Services (both registered and unregistered users).</p>

            <h3>2. WHAT INFORMATION DO WE COLLECT?</h3>

            <p>We collect personal information to provide Company Services and for our business operations. If you choose not to provide the information we request from you, we may not be able to provide you with the services you require. We describe the main types of personal information we collect and the main reasons why we collect that information below.</p>

            <p>Registration Information is the information you provide to Company when you register for or acquire a Company Service. This may include information you provide us to:</p>
            <ul className="styleul">    
                <li>create an account;</li>
                <li>become a subscriber;</li>
                <li>receive a newsletter; or</li>
            </ul>
            <p>Registration Information includes, for example, your name, delivery or postal address, email address, gender and birthday.</p>

            <p>You are able to use some Company Services without providing any personal information (for example when browsing our digital products as a casual user) or by providing an alias. We usually tell you when you are able to use Company Services in this way.</p>

            <p>Public Information and Posts include comments or content that you post to the online Company Services and the information about you that comes with those posts. This may include your name, user name, comments, likes, tweets, status, profile information and picture(s). Public Information and Posts are available to everyone who views our websites and may be displayed in search results on external search engines, even after you cancel your account with us.</p>

            <p>Information from third party Social Media. If you access or log-in to a Company Service through a third party social media service or connect a Company Service to your social media profile, we may also include information from that social media service. This may include:</p>
            <ul className="styleul">
                <li>your user name for that service;</li>
                <li>any information or content you have permitted the social media service to share with us (such as your profile picture(s), email address, followers or friends lists); and</li>
                <li>any other information you have made public (including other posts you make using your social media profile).</li>
            </ul>
            <p>We do not collect your social media profile password. When you access Company Services through your social media profile, or when you connect a Company Service to your social media profile, you authorise us to collect and handle your personal information in accordance with this Privacy Policy.</p>

            <p>Activity Information – cookies and other technologies. When you visit Company's websites (including to access and use Company Services) we and certain third parties such as Facebook may collect information about your visit. For example, in order to connect you to the Company Services, our servers receive and record information about your computer, device, and browser, which may include your IP address, browser type, and other software or hardware information. If you access the Company Services from a mobile or other device, we may collect a unique device identifier assigned to that device, geolocation data, or other transactional information from that device.
            For more information about cookies and tracking technologies and how to block cookies, see our Cookie Policy.</p>

            <p>Information from Other Sources. We may supplement the information we collect about you with information from other sources. This may include information from publicly available sources (such as other published content) and data providers, as well as information from our business partners or related and affiliated companies in Australia or internationally. Our related companies include those listed at the end of this Privacy Policy as well as the companies which operate Foxtel, Fox Sports Australia, Punters.com.au, Racenet.com.au, odds.com.au and Greyhound Racing.</p>

            <p>Information in relation to other persons who deal with us. When you apply for a job or contract with us we may collect information about you to help us decide whether to offer you the job or contract. For example, we may collect information about how to contact you, your education or work history, or other information included in your CV or résumé. We may collect this information from you, or from any recruitment consultant, your previous employers, or other referees. This Privacy Policy does not apply to our collection, use and disclosure of information:</p>
            <ul className="styleul">
                <li>we hold about you once you become an employee;</li>
                <li>we keep after your employment relationship with us ends,</li>
            </ul>
            <p>where that collection, use or disclosure relates to your employee relationship with us and the records we hold about you as an employee.</p>

            <p>Company also collects personal information about our suppliers and individuals employed by our suppliers (including service and content providers), contractors, dealers, related companies, agents and corporate customers. We will explain why we are collecting this information and how we will use that information, unless it obvious from the circumstances.</p>

            <h3>3. HOW DO WE USE YOUR INFORMATION?</h3>

            <p>We use the information we collect about you to provide Company Services to you. As part of our service to you we may use your personal information:</p>
            <ul className="styleul">
                <li>to fulfill administrative functions associated with these services (for example billing, credit and account management);</li>
                <li>to enter into contracts with you or third parties;</li>
                <li>to measure and improve Company Services and their individual features;</li>
                <li>for other marketing and client relationship purposes;</li>
                <li>to provide you with customer support; and</li>
                <li>to respond to your enquiries.</li>
            </ul>

            <p>We may also remove certain information or alter the information we collect about you so you can no longer be identified from that information. We do this so that we can use it or disclose it to third parties for other purposes.</p>

            <p>We use the information we collect about you for the following additional purposes:</p>

            <p>Research and data analysis. We may also use your information for research and data analysis to improve Company Services. We may do this research or engage a service provider to do this.</p>

            <p>To allow service and content providers to assist us in providing and managing the Company Services. We may make your information available to certain third party service and content providers who help us manage or provide the Company Services or provide Company with related services. This might include providers of cloud services, website hosting service providers, debt collection service providers and direct marketing service providers. These third parties may be located in a range of different countries including the United Kingdom, United States of America and Singapore. Our agreements with these third party providers require them not to use your information except for the purpose for which your information was provided. We also require them to comply with relevant privacy laws.</p>

            <p>To allow social sharing functionality. If you log in with or connect a social media profile with your Company Services account, we may share your personal information (including your user name, picture, tweets, likes and posts) with other Company Services users and your friends or followers linked to your social media profile. We may also share the same information with the social media service provider.</p>

            <p>By logging in with or connecting your Company Services account with a social media profile, you authorise us to share your personal information with the social media service provider, other users and your friends or followers. You understand that the social media service provider may handle this information in accordance with its own privacy policy. If you do not want your personal information shared in this way, please do not connect your social media profile with your Company Services account and do not participate in social sharing on Company Services.</p>

            <p>To provide co-branded services and features. We may offer co-branded services, such as competitions or other promotions together with a third party ("Co-Branded Services"). When this happens, we may share the information you submit in connection with the Co-Branded Service with the third party, or with our service providers who assist us to host Co Branded Services. The third party’s use of your information will be governed by their own privacy policy. We will obtain your consent to share your personal information with the third party. If you do not provide this consent, you may not be able to access that co-branded service.</p>

            <p>To deliver relevant advertisements and content. Company and our advertisers and advertising networks may use the information we collect about you to provide you with relevant advertisements and editorial content when you use Company Services. We may also use your personal information so that relevant advertising can be provided to you when you use services or visit websites of other organisations.</p>

            <p>We may combine information that we hold about you with information about you that we collect from other trusted businesses with whom you also have a relationship or from public sources and we may associate your browser and/or device with other browsers or devices you use. We may also share information we hold about you with those trusted businesses so that they can do the same thing.</p>

            <p>We and those trusted businesses do this in order to enable the development of consumer insights about you so that each of us can serve you better. This includes being able to better understand your preferences and interests, personalise your experience, enhance the products and services you receive, and to tell you about products and services. Where those insights are provided to others for their own marketing purposes, such insights do not contain any information that personally identifies you. We may also use third party service providers to undertake the process of creating these consumer insights.</p>

            <p>In addition, we engage third-party advertising networks to help us target our messaging to you through interest-based and contextual means. Through these ad networks, we can track your online activities over time and across third-party websites and apps by obtaining information through automated means, as described above. This data collection takes place both on our sites, apps and through marketing emails and newsletters which we send you and on third-party websites and apps that participate in these ad networks. This process also helps us track the effectiveness of our marketing efforts.</p>

            <p>To contact you. From time to time, we may send you promotional materials or other information which we think may interest you. For example, we may send you an invitation to participate in various activities (such as customer surveys) or special offers.</p>

            <p>To share with business partners. Company may share your information with business partners so they can send you marketing communications. This will only happen if you have expressly agreed to this.</p>

            <p>To protect the rights of Company and others. We may share your information with other third parties where we are lawfully entitled to do so. This includes when we have a reasonable basis to believe that sharing your information is necessary to:</p>
            <ul className="styleul">
                <li>protect, enforce or defend the legal rights, privacy, safety, or property of Company, our related companies or their employees, agents and contractors (including enforcement of our agreements and our terms of use);</li>
                <li>protect the safety, privacy, and security of users of the Company Services or members of the public;</li>
                <li>protect against fraud or for risk management purposes;</li>
                <li>comply with the law or legal process in any country; or</li>
                <li>respond to requests from public and government authorities.</li>
            </ul>
            <p>To complete a merger or sale of assets. If Company sells all or part of its business or assets, we may disclose your information to the party or parties involved in the sale transaction. We may also do this if Company is involved in a merger or transfer of all or a material part of its business. We may disclose your information prior to the sale, transfer or merger so that the party or parties involved can consider the transaction and complete any due diligence.</p>

            <h3>4. HOW DO WE PROTECT YOUR INFORMATION?</h3>

            <p>We use reasonable measures to safeguard the personal information we hold about you from loss, theft and unauthorised use, disclosure or modification, including:
            System security We take reasonable steps to prevent unauthorised access to our online and computerised systems by using measures such as firewalls, data encryption, virus detection methods, and password restricted access.</p>

            <p>Property security At our business premises, we may engage security personnel and use ID cards to restrict access to those premises.</p>

            <p>Staff training We train our staff to handle your information in accordance with this Privacy Policy and applicable privacy laws.</p>

            <p>Third parties We take reasonable steps to ensure that third parties who store or assist us to store your personal information adopt appropriate security measures.</p>

            <h3>5. HOW CAN YOU ACCESS YOUR INFORMATION?</h3>

            <p>If you would like to access, review, correct or update your personal information, you may contact us (see section 8). When you contact us, please provide your name and contact details (including your email address, address, and telephone number) and specify clearly what information you would like to access, review, correct or update. We will need to share your information with others who can help us respond to your request. We will try to respond to your request as soon as reasonably practicable. If we refuse your request we will generally tell you why.</p>

            <h3>6. HOW CAN YOU OPT OUT?</h3>

            <p>Online advertising. You can block certain advertisements by blocking or deleting cookies in your browser settings. However, we use cookies to provide some of our Company Services and you may not be able to use these Company Services if you do this. For more information about how we use cookies, see our Cookie Policy.</p>

            <p>Direct marketing. If you do not want to receive promotional messages from us, you can change your account settings or follow the unsubscribe instructions which we include at the bottom of our emails and text messages. If you have installed our app, you can stop push notifications by changing the settings in the app or on your mobile device.
            If you have linked any of your social media profiles to your account for any Company Service, you can cancel that link by changing your account settings. If you need any assistance with changing your account settings, please call us (see Section 8).</p>

            <h3>7. OTHER IMPORTANT INFORMATION FOR YOU</h3>

            <p>Updates to Privacy Policy. We may modify this Privacy Policy at any time. The date at the top of this Privacy Policy tells you when it was last updated. Any changes to this Privacy Policy become effective when we publish it online. If you do not agree with changes to this Privacy Policy, you may be able to terminate your account with us.</p>

            <p>Location of Data. Some Company Services are hosted in and managed outside of Australia, including in the United Kingdom, the United States of America and Singapore. In dealing with Us, you consent to this practice, understanding that your personal information may be accessible from or transmitted outside Australia.</p>

            <p>Collection of Personal Financial Information by a Payment Service provider. Sometimes we allow you to use an unaffiliated payment service to purchase a product or make payments for Company Services (“Payment Service”). If you use a Payment Service, you will be directed to the webpage for that Payment Service. Any information you provide when using a Payment Service may be collected and used by the Payment Service provider in accordance with that provider's privacy policy. You should check the provider's privacy policy to understand how it will handle your information.</p>

            <p>Data Retention. Even after you cancel your account with us, we will retain your information for as long as we reasonably need it for the purposes outlined in this Privacy Policy. For example, we may retain your information to prevent fraud, or to maintain systems security. We may also retain your information if required or allowed to by law, regulation or relevant standards and codes of conduct, or to fulfil our contractual obligations to a third party. In certain circumstances, including where we are prevented by technical or systems constraints, we may not be able to remove all of your personal information.</p>

            <p>If you share your information with a social media service (including by posting information to that service), this information may not be removed even after you cancel your account. If you delete your account with us, your account information may still be accessible by others for a short period of time because of the way that your information is stored on the internet.</p>

            <p>Sensitive Information. We ask that you not provide us with any sensitive information (such as information about your racial or ethnic origin, religious or other beliefs, health, criminal background or trade union membership) when using Company Services, or otherwise. If you do provide us with any sensitive information, you consent to us collecting and handling that information in accordance with this Privacy Policy.</p>

            <p>Complaint. If you think we have breached the Privacy Act 1988 (Cth) or other applicable privacy laws, you can contact us (see Section 8) to make a complaint. When you contact us, please include your name and contact details (including your email address, address, and telephone number) and clearly describe your complaint. We will need to share your information with others who can assist in responding to your complaint. We will contact you within 7 days to let you know the next steps in resolving your complaint and to obtain any further information we need to consider your complaint. As soon as reasonably practicable and in any event within 30 days, we will let you know our decision. If you are not satisfied with our response, you may refer your complaint to the Office of the Australian Information Commissioner (www.oaic.gov.au).</p>

            <h3>8. HOW YOU CAN CONTACT US</h3>

            <p>If you would like to raise a privacy concern in relation to information (including photos or videos) which we have published in any of our print or digital publications, please email: privacy@catheterBuddy.com.</p>
          </>
        );
        break;
      case "cookie-policy":
        document.title = "Cookie Policy | CatheterBuddy | Medical Device Compatibility Checker";
        document.getElementById('description_meta_utf8').setAttribute("content", "When you visit, access, or use the website, mobile site, application, electronic newsletter or widget that links to…");
        setTitle(
          <>
            <p>Cookies policy</p>
          </>
        );
        setText(
          <>
            <h3>General Overview</h3>

            <p>When you visit, access, or use the website, mobile site, application, electronic newsletter or widget that links to this Cookie Policy (collectively "Site") CatheterBuddy entities (listed in section 9 of our Privacy Policy) ("News", "us," or "we") use cookies and other tracking technologies to deliver and improve the Site, and to display relevant content, products, services and advertising.</p>

            <p>This Cookie Policy explains these technologies, including cookies, local storage, pixels, web beacons, and flash cookies, and how you can control them. In this policy, we will refer to all these technologies as "Cookies".</p>

            <p>We hope that this Cookie Policy helps you understand, and feel more confident about, our use of Cookies. If you have any further queries or requests, please contact us at privacy@catheterbuddy.com.</p>

            <p>By using the Site, you agree that we can use these Cookies as described in this Cookie Policy. We may change this Cookie Policy at any time. Please take a look at the Last Updated date at the start of this policy to see when this Cookie Policy was last updated. Any changes in this Cookie Policy will become effective when we make the revised Cookie Policy available on or through the Site.</p>

            <h3>1. WHAT IS A COOKIE?</h3>

            <p>Cookies and other tracking technologies (such as browser cookies and local storage, pixel beacons, and Adobe Flash technology including cookies) are comprised of small bits of data or code that often include a de-identified or anonymous unique identifier. Websites, apps and other services send this data to your browser (on your computer or mobile device) when you first request a web page and then store the data on your computer so that such websites, apps and other services can access information when you make subsequent requests for pages from that service. They are widely used in order to make websites work, or work in a better, more efficient way. For example, they can recognize you and remember important information that will make your use of a website more convenient (e.g., by remembering your user preferences).</p>

            <h3>2. WHAT COOKIES DO WE USE?</h3>

            <p>We use a variety of different types of Cookies on our Sites. Different Cookies have different specific purposes but in general they are all used so that we can improve your experience in using our Sites and interacting with us. Some of the purposes of different Cookies we use are described below.</p>

            <p>(A) Some Cookies are essential to the Site in order to facilitate our log-in process and enable you to move around it and to use its features. Without these Cookies, we may not be able to provide certain services or features, and the Site will not perform as smoothly for you as we would like.</p>

            <p>(B) We may use Cookies to allow us to remember the choices you make while browsing the Site, and to provide enhanced and more personalized content and features, such as customizing a certain webpage, providing relevant advertising or editorial content, remembering if we have asked you to participate in a promotion and for other services you request, like watching a video or commenting on a blog.</p>

            <p>(C) We may use Cookies to receive and record information about your computer, device, and browser, potentially including your IP address, browser type, and other software or hardware information. If you access the Site from a mobile or other device, we may collect a unique device identifier assigned to that device ("UDID"), geolocation data, or other transactional information for that device.</p>

            <p>(D) We and our service providers and advertisers may use analytics Cookies, which are sometimes called performance cookies, to collect information about your use of the Site and enable us to improve the way it works. Analytics Cookies collect information about how you use the Site, for instance, which pages you go to most. The information allows us to see the overall patterns of usage on the Site, help us record any difficulties you have with the Site and show us whether our advertising is effective or not.</p>

            <p>(E) We and our service providers and advertisers may use advertising Cookies to deliver ads that we believe are more relevant to you and your interests. For example, we may use targeting or advertising Cookies to limit the number of times you see the same ad on our Site, to help measure the effectiveness of our advertising campaigns as well as to customize the advertising and content you receive on our Site.</p>

            <p>(F) Social plug-in tracking Cookies can be used to track both members and non-members of social networks for additional purposes such as behavioural advertising, analytics, and market research.</p>

            <p>We aggregate information received from the various Cookies placed on your browser by our connected network of sites. We may also link our anonymous Cookie ID's with the anonymous Cookie ID's used by third parties, including some of our service providers. This improves our ability to provide you with more relevant editorial and advertising content based on your activity across our connected network. We may also associate your browser and/or device with other browsers or devices you use.</p>

            <p>All information derived from these Cookies may be combined with other information acquired from third parties, and we may share this information with other organizations, such as advertisers, provided it is anonymised. For more information relating to this see our Data Usage Policy and our Privacy Policy.</p>

            <h3>3. HOW DO THIRD PARTIES USE COOKIES ON THE SITE?</h3>

            <p>In some circumstances, we may work with third parties to provide certain services on our Site. For example, we use analytics services supported by third party companies who generate analytics Cookies on our behalf, including Google Analytics. We may not have access to these Cookies, although we may use statistical information arising from the Cookies provided by these third parties to customize content and for the other purposes described above. These companies may also transfer this information to other parties including where they are required to do so by law, or where such other parties process the information on their behalf.</p>

            <p>Third-party advertisers and other organizations may also use their own Cookies to collect information about your activities on our Site and/or the advertisements you have clicked on. This should only happen in compliance with our Advertiser Data Policy or otherwise as approved by us. Again, this information, which may be combined with other information acquired from third parties, may be used by them to provide you with advertisements (both on our Site and through other means) that they believe are most likely to be of interest to you based on content you have viewed.</p>

            <p>Third-party advertisers may also use this information to measure the effectiveness of their advertisements and to track aggregate usage statistics.
            We may not control these third-party Cookies and their use may be governed by the privacy policies of the third parties employing these Cookies. To learn about these Cookies and to disable or reject third-party Cookies, please refer to the relevant third party’s website.</p>

            <h3>4. HOW CAN YOU OPT OUT?</h3>

            <p>Most browsers are initially set to accept cookies, but you can change your settings to notify you when a cookie is being set or updated, or to block cookies altogether. Some browsers may include setting that restrict cookies to "websites that you visit". Be aware that at times, before taking you to a page in our network which you have requested to visit, we may momentarily redirect your browser to another of our network sites to place a Cookie onto your browser. This is done to enhance your experience across our connected network of sites and to improve our ability to provide you with more relevant editorial and advertising content based on your activity across our connected network. Such local redirects may be included as "websites that you visit". Users can manage the use of Flash technologies, with the Flash management tools available at Adobe’s website. Please note that by blocking any or all cookies you may not have access to certain features, content or personalization available through the Company Services.</p>

            <p>To opt out of Google Analytics, go to <a href="https://tools.google.com/dlpage/gaoptout" rel="noreferrer">https://tools.google.com/dlpage/gaoptout</a>.</p>
          </>
        );
        break;
      default:
        break;
    }
  }, [forms]);
  if(forms == 'contact-us'){
    return (
      <>
        <NavBarIfLoggedIn></NavBarIfLoggedIn>
        <div className="page-title">
          <img src={background} alt="Catheter background"></img>
          {title}
        </div>
        <div className="page-container">
          <div className="page-text-container">
            <div className="page-text">
              <form className="contact-label">
                <Form.Group controlId="formGroupCompany">
                  <Form.Label>Name</Form.Label>
                  <input
                    className="form form-control"
                    rows="10"
                    type="text" 
                    name={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required 
                  />
                </Form.Group>
                <Form.Group controlId="formGroupCompany">
                  <Form.Label>Mobile</Form.Label>
                  <input
                    className="form form-control"
                    rows="10"
                    type="text" 
                    name={mobile}
                    onChange={(e) => {
                      setMobile(e.target.value);
                    }}
                    required 
                  />
                </Form.Group>
                <Form.Group controlId="formGroupCompany">
                  <Form.Label>Email</Form.Label>
                  <input
                    className="form form-control"
                    rows="10"
                    type="text" 
                    name={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required 
                  />
                </Form.Group>
                <Form.Group controlId="formGroupCompany">
                  <Form.Label>Message</Form.Label>
                  <textarea
                    className="form form-control"
                    rows="10"
                    type="text" 
                    name={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    required 
                  />
                </Form.Group>
                <div>
                  {sendContact ? validate : ""}
                </div>
                <Button style={{ marginLeft: "25px", background: "#199e53" }} onClick={sendContact}>Send</Button>
              </form>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </>
    );
  }
  return (
    <>
      <NavBarIfLoggedIn></NavBarIfLoggedIn>
      <div className="page-title">
        <img src={background} alt="Catheter background"></img>
        {title}
      </div>
      <div className="page-container">
        <div className="page-text-container">
          <div className="page-text">
            {text}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default LegalPages;
