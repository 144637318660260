import { useEffect } from 'react'
import { useHistory, useParams } from "react-router";
import Swal from "sweetalert2";
import background from "../models/background-2.jpg";
import axios from "axios";


const ActivateUser = () => {
  const { token } = useParams();
  let history = useHistory();

  const handleError = () => {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error has occurred activating your account. Please, try again in a few minutes'
    }).then((result) => {
      if (result.isConfirmed) {
        history.push('/')
      }
    })
  }

  const handleActivation = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/users/activate/${token}`)
    .then(res => {
      if (res.status == 200) {
        Swal.fire({
          icon: 'success',
          title: 'Congrulations',
          text: 'Your account has been activated successfully'
        }).then((result) => {
          if (result.isConfirmed) {
            history.push('/')
          }
        })
      } else if(res.status == 403){
        Swal.fire({
          icon: 'warning',
          title: 'Token Expired',
          text: 'Your registration request has expired. Do you want us to send you a new activation?',
          showDenyButton: true,
          denyButtonText: `Don't send`,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push('/reactivate')
          } else if (result.isDenied) {
            history.push('/')
          }
        })
      } else {
        console.log(res)
        handleError()
      }
    })
    .catch(err => {
      console.log(err.message)
      Swal.fire({
        icon: 'warning',
        title: 'Token Expired',
        text: 'Your registration request has expired. Do you want us to send you a new activation?',
        showDenyButton: true,
        denyButtonText: `Don't send`,
      }).then((result) => {
        if (result.isConfirmed) {
          history.push('/reactivate')
        } else if (result.isDenied) {
          history.push('/')
        }
      })
    })
  }

  useEffect(() => {
    if (token) {
      handleActivation()
    } else {
      handleError()
    }
  },[token])

  return (
    <body>
      <div className="page-title">
        <img src={background} alt="Catheter background"></img>
        <p>Activation Process</p>
      </div>
    </body>
  )
}

export default ActivateUser;