import PeopleIcon from "@material-ui/icons/People";
import BusinessIcon from "@material-ui/icons/Business";
import HeightIcon from "@material-ui/icons/Height";
import CategoryIcon from "@material-ui/icons/Category";
import SquareFootIcon from "@material-ui/icons/SquareFoot";
import StorageIcon from "@material-ui/icons/Storage";
import HomeIcon from "@material-ui/icons/Home";

export const SidebarData = [
  {
    index: 1,
    link: "/",
    icon: <HomeIcon color="white"></HomeIcon>,
    text: "Home",
  },
  {
    index: 3,
    link: "/admin/see-products",
    icon: <StorageIcon color="white"></StorageIcon>,
    text: "Products",
  },
  {
    index: 4,
    link: "/admin/see-companies",
    icon: <BusinessIcon color="white"></BusinessIcon>,
    text: "Companies",
  },
  {
    index: 5,
    link: "/admin/see-producttypes",
    icon: <CategoryIcon color="white"></CategoryIcon>,
    text: "Product Types",
  },
  // {
  //   index: 6,
  //   link: "/admin/see-measuretypes",
  //   icon: <HeightIcon color="white"></HeightIcon>,
  //   text: "Measure Types",
  // },
  // {
  //   index: 7,
  //   link: "/admin/see-metrictype",
  //   icon: <SquareFootIcon color="white"></SquareFootIcon>,
  //   text: "Metric Types",
  // },
  {
    index: 8,
    link: "/admin/see-users",
    icon: <PeopleIcon color="white"></PeopleIcon>,
    text: "Users",
  },
];
