import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import logo from "../../pages/models/logocatheterbuddybeta.png";
import facebooklogo from "../../pages/models/icono-facebook.png";
import twitterlogo from "../../pages/models/icono-twitter.png";
import linkedinlogo from "../../pages/models/icono-linkedin.png";
import youtubelogo from "../../pages/models/icono-youtube.png";

const informationLinks = [
  {
    url: "/forms/about-us",
    name: "About us",
  },
  {
    url: "/forms/features",
    name: "Features",
  },
  {
    url: "/forms/faq",
    name: "FAQ",
  },
  {
    url: "/forms/contact-us",
    name: "Contact Us",
  }
];

const legalLinks = [
  {
    url: "/forms/terms-of-use",
    name: "Terms of Use",
  },
  {
    url: "/forms/privacy-policy",
    name: "Privacy Policy",
  },
  {
    url: "/forms/legal-warning",
    name: "Legal Warning",
  },
  {
    url: "/forms/cookie-policy",
    name: "Cookies Policy",
  },
];

const upHeader = function(){
  window.scrollTo({
    top: 0,
    left: 0
  });
};

const Footer = () => {
  return (
    <div>
      <div className="homepage-footer">
        <div className="catheterbuddy-description">
          <img src={logo} alt="Catheterbuddylogo" className="img-logo-footer"/>
          <p>
            Catheter Buddy is <b>the First Medical Device Compatibility Checker online</b> for HEALTHCARE PROFESIONALS
          </p>
        </div>
        <div className="catheterbuddy-information">
          <h1>Information</h1>
          {informationLinks.map((links, index) => (
            <div key={`footer1-${index}`}>
              <NavLink
                key={`footer1-${index}`}
                to={links.url}
                style={{ textDecoration: "none", color: "#616161" }}
                className="Hover"
                onClick={() => upHeader()}
              >
                {links.name}
              </NavLink>
              <br />
            </div>
          ))}
        </div>
        <div className="catheterbuddy-legal">
          <h1>Legal</h1>
          {legalLinks.map((links, index) => (
            <div key={`footer1-${index}`}>
              <NavLink
                key={`footer2-${index}`}
                to={links.url}
                style={{ textDecoration: "none", color: "#616161" }}
                className="Hover"
                onClick={() => upHeader()}
              >
                {links.name}
              </NavLink>
              <br />
            </div>
          ))}
        </div>
        <div className="catheterbuddy-address">
          <h1>Our Address</h1>
          {/* <h1>F Escondrillas and T.M WEarkel in Endovascular Digital Solutions 11-132 Queenscliff rd. Queenscliff(nsw).</h1> */}
          <span>info@catheterbuddy.com</span>
        </div>

        <div className="social-media-footer">
          <img src={facebooklogo} alt="" />
          <img src={twitterlogo} alt="" />
          <img src={linkedinlogo} alt="" />
          <img src={youtubelogo} alt="" />
        </div>
      </div>
      <div className="copyright-footer">
        <p>{`Copyright @${new Date().getFullYear()} All rights reserved | CatheterBuddy`}</p>
      </div>
    </div>
  );
};

export default Footer;
