import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer/Footer";
import Form from "react-bootstrap/Form";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { NavLink, useParams, useHistory } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import SendIcon from '@material-ui/icons/Send';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MessageIcon from '@material-ui/icons/Message';
import MailIcon from '@material-ui/icons/Mail';
import ReplyIcon from '@material-ui/icons/Reply';
import "./FigurePage.css";
import { authHeader } from "../../../services/AuthHeader";
import CatheterForm from "../../../components/Catheters/CatheterForm";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { NavBarIfLoggedIn } from "../../../middleware/IfLoggedIn";
import Swal from "sweetalert2";
import { Dropdown, Modal } from 'react-bootstrap';
//import 'bootstrap/dist/css/bootstrap.min.css';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas';


const { REACT_APP_API_URL } = process.env;

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});

const data_country = [
  {
    "label": "Afganistán",
    "value": "+93"
  },
  {
    "label": "Albania",
    "value": "+355"
  },
  {
    "label": "Argelia",
    "value": "+213"
  },
  {
    "label": "Samoa Americana",
    "value": "+1684"
  },
  {
    "label": "Andorra",
    "value": "+376"
  },
  {
    "label": "Angola",
    "value": "+244"
  },
  {
    "label": "Anguilla",
    "value": "+1264"
  },
  {
    "label": "Antártida",
    "value": "+672"
  },
  {
    "label": "Antigua y Barbuda",
    "value": "+1268"
  },
  {
    "label": "Argentina",
    "value": "+54"
  },
  {
    "label": "Armenia",
    "value": "+374"
  },
  {
    "label": "Aruba",
    "value": "+297"
  },
  {
    "label": "Australia",
    "value": "+61"
  },
  {
    "label": "Austria",
    "value": "+43"
  },
  {
    "label": "Azerbaiyán",
    "value": "+994"
  },
  {
    "label": "Bahamas",
    "value": "+1242"
  },
  {
    "label": "Baréin",
    "value": "+973"
  },
  {
    "label": "Banglades",
    "value": "+880"
  },
  {
    "label": "Barbados",
    "value": "+1246"
  },
  {
    "label": "Bielorrusia",
    "value": "+375"
  },
  {
    "label": "Bélgica",
    "value": "+32"
  },
  {
    "label": "Belice",
    "value": "+501"
  },
  {
    "label": "Benin",
    "value": "+229"
  },
  {
    "label": "Bermudas",
    "value": "+1441"
  },
  {
    "label": "Butan",
    "value": "+975"
  },
  {
    "label": "Bolivia",
    "value": "+591"
  },
  {
    "label": "Bosnia-Herzegovina",
    "value": "+387"
  },
  {
    "label": "Botsuana",
    "value": "+267"
  },
  {
    "label": "Brasil",
    "value": "+55"
  },
  {
    "label": "Territorio Británico del Océano Índico",
    "value": "+246"
  },
  {
    "label": "Brunei",
    "value": "+673"
  },
  {
    "label": "Bulgaria",
    "value": "+359"
  },
  {
    "label": "Burkina Faso",
    "value": "+226"
  },
  {
    "label": "Burundi",
    "value": "+257"
  },
  {
    "label": "Camboya",
    "value": "+855"
  },
  {
    "label": "Camerún",
    "value": "+237"
  },
  {
    "label": "Canadá",
    "value": "+1"
  },
  {
    "label": "Cabo Verde",
    "value": "+238"
  },
  {
    "label": "Islas Caimán",
    "value": "+ 345"
  },
  {
    "label": "República Centroafricana",
    "value": "+236"
  },
  {
    "label": "Chad",
    "value": "+235"
  },
  {
    "label": "Chile",
    "value": "+56"
  },
  {
    "label": "China",
    "value": "+86"
  },
  {
    "label": "Isla de Navidad",
    "value": "+61"
  },
  {
    "label": "Islas Cocos",
    "value": "+61"
  },
  {
    "label": "Colombia",
    "value": "+57"
  },
  {
    "label": "Comoras",
    "value": "+269"
  },
  {
    "label": "Congo",
    "value": "+242"
  },
  {
    "label": "República Democrática del Congo",
    "value": "+243"
  },
  {
    "label": "Islas Cook",
    "value": "+682"
  },
  {
    "label": "Costa Rica",
    "value": "+506"
  },
  {
    "label": "Costa de Marfil",
    "value": "+225"
  },
  {
    "label": "Croacia",
    "value": "+385"
  },
  {
    "label": "Cuba",
    "value": "+53"
  },
  {
    "label": "Chipre",
    "value": "+537"
  },
  {
    "label": "Chequia",
    "value": "+420"
  },
  {
    "label": "Dinamarca",
    "value": "+45"
  },
  {
    "label": "Yibuti",
    "value": "+253"
  },
  {
    "label": "Dominica",
    "value": "+1767"
  },
  {
    "label": "República Dominicana",
    "value": "+1849"
  },
  {
    "label": "Ecuador",
    "value": "+593"
  },
  {
    "label": "Egipto",
    "value": "+20"
  },
  {
    "label": "El Salvador",
    "value": "+503"
  },
  {
    "label": "Guinea Ecuatorial",
    "value": "+240"
  },
  {
    "label": "Eritrea",
    "value": "+291"
  },
  {
    "label": "Estonia",
    "value": "+372"
  },
  {
    "label": "Etiopía",
    "value": "+251"
  },
  {
    "label": "Islas Malvinas",
    "value": "+500"
  },
  {
    "label": "Islas Feroe",
    "value": "+298"
  },
  {
    "label": "Fiyi",
    "value": "+679"
  },
  {
    "label": "Finlandia",
    "value": "+358"
  },
  {
    "label": "Francia",
    "value": "+33"
  },
  {
    "label": "Guayana Francesa",
    "value": "+594"
  },
  {
    "label": "Polinesia Francesa",
    "value": "+689"
  },
  {
    "label": "Gabón",
    "value": "+241"
  },
  {
    "label": "Gambia",
    "value": "+220"
  },
  {
    "label": "Georgia",
    "value": "+995"
  },
  {
    "label": "Alemania",
    "value": "+49"
  },
  {
    "label": "Ghana",
    "value": "+233"
  },
  {
    "label": "Gibraltar",
    "value": "+350"
  },
  {
    "label": "Grecia",
    "value": "+30"
  },
  {
    "label": "Groenlandia",
    "value": "+299"
  },
  {
    "label": "Granada",
    "value": "+1473"
  },
  {
    "label": "Guadalupe",
    "value": "+590"
  },
  {
    "label": "Guam",
    "value": "+1671"
  },
  {
    "label": "Guatemala",
    "value": "+502"
  },
  {
    "label": "Guernsey",
    "value": "+44"
  },
  {
    "label": "Guinea",
    "value": "+224"
  },
  {
    "label": "Guinea-Bisau",
    "value": "+245"
  },
  {
    "label": "Guyana",
    "value": "+595"
  },
  {
    "label": "Haití",
    "value": "+509"
  },
  {
    "label": "Ciudad del Vaticano",
    "value": "+379"
  },
  {
    "label": "Honduras",
    "value": "+504"
  },
  {
    "label": "Hong Kong",
    "value": "+852"
  },
  {
    "label": "Hungría",
    "value": "+36"
  },
  {
    "label": "Islandia",
    "value": "+354"
  },
  {
    "label": "India",
    "value": "+91"
  },
  {
    "label": "Indonesia",
    "value": "+62"
  },
  {
    "label": "Irán",
    "value": "+98"
  },
  {
    "label": "Iraq",
    "value": "+964"
  },
  {
    "label": "Irlanda",
    "value": "+353"
  },
  {
    "label": "Isla de Man",
    "value": "+44"
  },
  {
    "label": "Israel",
    "value": "+972"
  },
  {
    "label": "Italia",
    "value": "+39"
  },
  {
    "label": "Jamaica",
    "value": "+1876"
  },
  {
    "label": "Japón",
    "value": "+81"
  },
  {
    "label": "Jersey",
    "value": "+44"
  },
  {
    "label": "Jordania",
    "value": "+962"
  },
  {
    "label": "Kazajistán",
    "value": "+7"
  },
  {
    "label": "Kenia",
    "value": "+254"
  },
  {
    "label": "Kiribati",
    "value": "+686"
  },
  {
    "label": "Corea del Norte",
    "value": "+850"
  },
  {
    "label": "Corea del Sur",
    "value": "+82"
  },
  {
    "label": "Kosovo",
    "value": "+383"
  },
  {
    "label": "Kuwait",
    "value": "+965"
  },
  {
    "label": "Kirguistán",
    "value": "+996"
  },
  {
    "label": "Laos",
    "value": "+856"
  },
  {
    "label": "Letonia",
    "value": "+371"
  },
  {
    "label": "Líbano",
    "value": "+961"
  },
  {
    "label": "Lesoto",
    "value": "+266"
  },
  {
    "label": "Liberia",
    "value": "+231"
  },
  {
    "label": "Libia",
    "value": "+218"
  },
  {
    "label": "Liechtenstein",
    "value": "+423"
  },
  {
    "label": "Lituania",
    "value": "+370"
  },
  {
    "label": "Luxemburgo",
    "value": "+352"
  },
  {
    "label": "Macao",
    "value": "+853"
  },
  {
    "label": "República de Macedonia",
    "value": "+389"
  },
  {
    "label": "Madagascar",
    "value": "+261"
  },
  {
    "label": "Malaui",
    "value": "+265"
  },
  {
    "label": "Malasia",
    "value": "+60"
  },
  {
    "label": "Maldivas",
    "value": "+960"
  },
  {
    "label": "Malí",
    "value": "+223"
  },
  {
    "label": "Malta",
    "value": "+356"
  },
  {
    "label": "Islas Marshall",
    "value": "+692"
  },
  {
    "label": "Martinica",
    "value": "+596"
  },
  {
    "label": "Mauritania",
    "value": "+222"
  },
  {
    "label": "Mauricio",
    "value": "+230"
  },
  {
    "label": "Mayotte",
    "value": "+262"
  },
  {
    "label": "México",
    "value": "+52"
  },
  {
    "label": "Estados Federados de Micronesia",
    "value": "+691"
  },
  {
    "label": "Moldavia",
    "value": "+373"
  },
  {
    "label": "Monaco",
    "value": "+377"
  },
  {
    "label": "Mongolia",
    "value": "+976"
  },
  {
    "label": "Montenegro",
    "value": "+382"
  },
  {
    "label": "Montserrat",
    "value": "+1664"
  },
  {
    "label": "Marruecos",
    "value": "+212"
  },
  {
    "label": "Mozambique",
    "value": "+258"
  },
  {
    "label": "Birmania",
    "value": "+95"
  },
  {
    "label": "Namibia",
    "value": "+264"
  },
  {
    "label": "Nauru",
    "value": "+674"
  },
  {
    "label": "Nepal",
    "value": "+977"
  },
  {
    "label": "Holanda",
    "value": "+31"
  },
  {
    "label": "Antillas Holandesas",
    "value": "+599"
  },
  {
    "label": "Nueva Caledonia",
    "value": "+687"
  },
  {
    "label": "Nueva Zelanda",
    "value": "+64"
  },
  {
    "label": "Nicaragua",
    "value": "+505"
  },
  {
    "label": "Niger",
    "value": "+227"
  },
  {
    "label": "Nigeria",
    "value": "+234"
  },
  {
    "label": "Niue",
    "value": "+683"
  },
  {
    "label": "IslaNorfolk",
    "value": "+672"
  },
  {
    "label": "IslasMarianasdelNorte",
    "value": "+1670"
  },
  {
    "label": "Noruega",
    "value": "+47"
  },
  {
    "label": "Omán",
    "value": "+968"
  },
  {
    "label": "Pakistán",
    "value": "+92"
  },
  {
    "label": "Palaos",
    "value": "+680"
  },
  {
    "label": "Panamá",
    "value": "+507"
  },
  {
    "label": "Papúa Nueva Guinea",
    "value": "+675"
  },
  {
    "label": "Paraguay",
    "value": "+595"
  },
  {
    "label": "Perú",
    "value": "+51"
  },
  {
    "label": "Filipinas",
    "value": "+63"
  },
  {
    "label": "Islas Pitcairn",
    "value": "+872"
  },
  {
    "label": "Polonia",
    "value": "+48"
  },
  {
    "label": "Portugal",
    "value": "+351"
  },
  {
    "label": "Puerto Rico",
    "value": "+1939"
  },
  {
    "label": "Qatar",
    "value": "+974"
  },
  {
    "label": "Rumania",
    "value": "+40"
  },
  {
    "label": "Rusia",
    "value": "+7"
  },
  {
    "label": "Ruanda",
    "value": "+250"
  },
  {
    "label": "Reunion",
    "value": "+262"
  },
  {
    "label": "San Bartolome",
    "value": "+590"
  },
  {
    "label": "Santa Elena, Ascensión y Tristán de Acuña",
    "value": "+290"
  },
  {
    "label": "San Cristóbal y Nieves",
    "value": "+1869"
  },
  {
    "label": "Santa Lucía",
    "value": "+1758"
  },
  {
    "label": "Isla de San Martín",
    "value": "+590"
  },
  {
    "label": "San Pedro y Miquelon",
    "value": "+508"
  },
  {
    "label": "San Vicente y las Granadinas",
    "value": "+1784"
  },
  {
    "label": "Samoa",
    "value": "+685"
  },
  {
    "label": "San Marino",
    "value": "+378"
  },
  {
    "label": " Santo Tomé y Príncipe",
    "value": "+239"
  },
  {
    "label": "Arabia Saudita",
    "value": "+966"
  },
  {
    "label": "Senegal",
    "value": "+221"
  },
  {
    "label": "Serbia",
    "value": "+381"
  },
  {
    "label": "Seychelles",
    "value": "+248"
  },
  {
    "label": "Sierra Leona",
    "value": "+232"
  },
  {
    "label": "Singapur",
    "value": "+65"
  },
  {
    "label": "Eslovaquia",
    "value": "+421"
  },
  {
    "label": "Eslovenia",
    "value": "+386"
  },
  {
    "label": "Islas Salomón",
    "value": "+677"
  },
  {
    "label": "Somalia",
    "value": "+252"
  },
  {
    "label": "Sudáfrica",
    "value": "+27"
  },
  {
    "label": "Sudán del Sur",
    "value": "+211"
  },
  {
    "label": "España",
    "value": "+34"
  },
  {
    "label": "Sri Lanka",
    "value": "+94"
  },
  {
    "label": "Estado de Palestina",
    "value": "+970"
  },
  {
    "label": "Sudán",
    "value": "+249"
  },
  {
    "label": "Surinam",
    "value": "+597"
  },
  {
    "label": "Svalbard y Jan Mayen",
    "value": "+47"
  },
  {
    "label": "Suazilandia",
    "value": "+268"
  },
  {
    "label": "Suecia",
    "value": "+46"
  },
  {
    "label": "Suiza",
    "value": "+41"
  },
  {
    "label": "Siria",
    "value": "+963"
  },
  {
    "label": "Taiwán",
    "value": "+886"
  },
  {
    "label": "Tayikistán",
    "value": "+992"
  },
  {
    "label": "Tanzania",
    "value": "+255"
  },
  {
    "label": "Tailandia",
    "value": "+66"
  },
  {
    "label": "Timor Oriental",
    "value": "+670"
  },
  {
    "label": "Togo",
    "value": "+228"
  },
  {
    "label": "Tokelau",
    "value": "+690"
  },
  {
    "label": "Tonga",
    "value": "+676"
  },
  {
    "label": "Trinidad y Tobago",
    "value": "+1868"
  },
  {
    "label": "Túnez",
    "value": "+216"
  },
  {
    "label": "Turquía",
    "value": "+90"
  },
  {
    "label": "Turkmenistán",
    "value": "+993"
  },
  {
    "label": "Islas Turcas y Caicos",
    "value": "+1649"
  },
  {
    "label": "Tuvalu",
    "value": "+688"
  },
  {
    "label": "Uganda",
    "value": "+256"
  },
  {
    "label": "Ucrania",
    "value": "+380"
  },
  {
    "label": "Emiratos Árabes Unidos",
    "value": "+971"
  },
  {
    "label": "Reino Unido",
    "value": "+44"
  },
  {
    "label": "Estados Unidos",
    "value": "+1"
  },
  {
    "label": "Uruguay",
    "value": "+598"
  },
  {
    "label": "Uzbekistán",
    "value": "+998"
  },
  {
    "label": "Vanuatu",
    "value": "+678"
  },
  {
    "label": "Venezuela",
    "value": "+58"
  },
  {
    "label": "Vietnam",
    "value": "+84"
  },
  {
    "label": "Islas Vírgenes Británicas",
    "value": "+1284"
  },
  {
    "label": "Islas Vírgenes de los Estados Unidos",
    "value": "+1340"
  },
  {
    "label": "Wallis y Futuna",
    "value": "+681"
  },
  {
    "label": "Yemen",
    "value": "+967"
  },
  {
    "label": "Zambia",
    "value": "+260"
  },
  {
    "label": "Zimbabue",
    "value": "+263"
  },
  {
    "label": "Åland",
    "value": "+358"
  }
];

const CatheterA = () => {
  let history = useHistory();
  let { catheter, historyid } = useParams();

  const [idProductA, setIdProductA] = useState("");
  const [idProductB, setIdProductB] = useState("");
  const [idProductC, setIdProductC] = useState("");
  const [idProductD, setIdProductD] = useState("");
  
  const [typeProductA, setTypeProductA] = useState("");
  const [typeProductB, setTypeProductB] = useState("");
  const [typeProductC, setTypeProductC] = useState("");
  const [typeProductD, setTypeProductD] = useState("");
  
  const [nameProductA, setNameProductA] = useState("");
  const [nameProductB, setNameProductB] = useState("");
  const [nameProductC, setNameProductC] = useState("");
  const [nameProductD, setNameProductD] = useState("");

  const [productALoaded, setProductALoaded] = useState(false);
  const [productBLoaded, setProductBLoaded] = useState(false);
  const [productCLoaded, setProductCLoaded] = useState(false);
  const [productDLoaded, setProductDLoaded] = useState(false);

  const [isCompatible, setIsCompatible] = useState("");

  const [productListGeneral, setProductListGeneral] = useState([]);
  const [filteredProductListGeneral,setFilteredProductListGeneral] = useState([]);
  const [selectedCatheter, setSelectedCatheter] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(""); //Este almacena si es A o B o C...
  const [productList, setProductList] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [idCompare, setIdCompare] = useState(null);
  const [show, setShow] = useState(false);
  const [typeSend, setTypeSend] = useState("");
  const handleClose = () => setShow(false);
  const [inputModal, setinputModal] = useState([]);
  const [errorListData, seterrorListData] = useState("");
  const [countryCode, secountryCode] = useState("");
  const [selectedCatheterType, setSelectedCatheterType] = useState(null);
  const colourOptions = [];
  
  useEffect(async () => { //Has history
    if(historyid){
      fillHistory(historyid);
    }
  }, [productListGeneral]);
  
  const fillHistory = async (history) => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/products/history`, {history: history});
    setIsVisible(false);
    checkIfCompatible(data.Status == 1 ? 200 : 401, data.Status == 1 ? "These products are Compatible!" : "These products are not Compatible.")
    if(data.ProductId) {
      const prA = await axios.get(`${process.env.REACT_APP_API_URL}/products/${data.ProductId}`);
      const prAData = fillSelectType(prA.data, "a");
      handleProduct("a", prAData);
    }
    if(data.ProductA) {
      const prB = await axios.get(`${process.env.REACT_APP_API_URL}/products/${data.ProductA}`);
      const prBData = fillSelectType(prB.data, "b");
      handleProduct("b", prBData);
    }
    if(data.ProductB) {
      const prC = await axios.get(`${process.env.REACT_APP_API_URL}/products/${data.ProductB}`);
      const prCData = fillSelectType(prC.data, "c");
      handleProduct("c", prCData);
    }
    if(data.ProductC) {
      const prD = await axios.get(`${process.env.REACT_APP_API_URL}/products/${data.ProductC}`);
      const prDData = fillSelectType(prD.data, "d");
      handleProduct("d", prDData);
    }
  }

  const fillSelectType = (x, selProd) => {
    let _nameSelect = "";
    let _selectType = "";

    switch(x.ProductTypeId) {
      case 1:
        _selectType = selProd !== "a" ? x.od_french : x.id_french ? x.id_french : x.gw_compact_mm;
        //_nameSelect = selProd !== "a" ? `${x.Name} OD ${x.od_french} Fr.` : x.id_french ? `${x.Name} ID ${x.id_french} Fr.` : x.gw_compact_mm ? `${x.Name} GW ${x.gw_compact_mm} mm.` : `${x.Name} OD ${x.od_french} Fr.`;
        _nameSelect = `${x.Name} OD ${x.od_french} Fr.`;
        return { ...x, selectType: _selectType, nameSelect: _nameSelect};
        break;
      case 2:
        return { ...x, selectType: x.id_french, nameSelect: `${x.Name} ID ${x.id_french} Fr.` };
        break;
      case 3:
        _selectType = selProd !== "a" ? x.od_french : x.id_french ? x.id_french : x.gw_compact_mm;
        //_nameSelect = selProd !== "a" ? `${x.Name} OD ${x.od_french} Fr.` : x.id_french ? `${x.Name} ID ${x.id_french} Fr.` : x.gw_compact_mm ? `${x.Name} GW ${x.gw_compact_mm} mm.` : `${x.Name} OD ${x.od_french} Fr.`;
        _nameSelect = `${x.Name} OD ${x.od_french} Fr.`;
        return { ...x, selectType: x._selectType, nameSelect: _nameSelect };
        break;
      case 4:
        return { ...x, selectType: x.od_inches, nameSelect: `${x.Name} OD ${x.od_inches} in.` };
        break;
      case 5:
        return { ...x, selectType: x.od_inches, nameSelect: `${x.Name} OD ${x.od_inches} in.` };
        break;
      case 6:
        return { ...x, selectType: x.od_inches, nameSelect: `${x.Name} OD ${x.od_inches} in.` };
        break;
      case 7:
        return { ...x, selectType: x.od_microns, nameSelect: `${x.Name} OD ${x.od_microns} um.` };
        break;
      case 8:
        return { ...x, selectType: x.od_mm, nameSelect: `${x.Name} ID ${x.od_mm} mm.`  };
        break;
      case 9:
        return { ...x, selectType: x.search_ballon_stents, nameSelect: `${x.Name} ${x.search_ballon_stents}` };
        break;
      case 10:
        return { ...x, selectType: x.search_ballon_stents, nameSelect: `${x.Name} ${x.search_ballon_stents}` };
        break;
      case 11:
        return { ...x, nameSelect: x.Name};
        break;
    }
  }

  useEffect(() => { //login Checker
    const statusSession = async () => {
      await axios(`${REACT_APP_API_URL}/users/session`, { headers: authHeader() }).catch((e) => {
        if(e.response.status === 503){
          localStorage.removeItem('authorization');
          Toast.fire({
            icon: 'danger',
            title: "The current session expired"
          });
          history.push('/login');
        }
      }); 
    };
    statusSession();

    const fetchDataGeneral = async () => {
      const result = await axios(`${process.env.REACT_APP_API_URL}/products/generalType`);
      setProductListGeneral(result.data);
      setFilteredProductListGeneral(result.data);
    };
    fetchDataGeneral();
  }, []);

  useEffect(() => {
    if(selectedProduct) setMapType(null, selectedProduct);
    setProductList([]);
    let _filteredList = [];
    for(let i = 0; i < productListGeneral.length ; i++) {
      if (selectedProduct !== "a") {
        //if (productListGeneral[i].Name !== "Sheaths") _filteredList.push(productListGeneral[i]);
        _filteredList.push(productListGeneral[i]);
      } else {
        if (productListGeneral[i].Name !== "Cerebral Coil" && productListGeneral[i].Name !== "Embolization Coil" && productListGeneral[i].Name !== "Guidewires" && productListGeneral[i].Name !== "Embolic Particle Beads") _filteredList.push(productListGeneral[i]);
      }
    };
    //console.log(_filteredList);
    setFilteredProductListGeneral(_filteredList);
  }, [selectedProduct]);
  
  const fetchData = async (e) => {
    let value = e.target.value;

    const data = filteredProductListGeneral.find(x => x.id == value);
    
    const result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/products/type`,
      data: {
        Description: value
      }
    });
    let response = [];
    if(data){
      switch (data.id) {
        case 1: //"Catheter":
        case 3: //"Microcatheter":
          result.data.map((x) => {
            const _el = response.find(e => e.Name === x.Name && e.od_french == x.od_french)
            if (_el != undefined) {
              //console.log("EL: ", _el, "X: ", x);
              if (_el.id_french != undefined) {
                if(x.id_french != undefined) {
                  if(x.id_french > _el.id_french) {
                    response.splice(response.indexOf(_el),1);
                    x.selectType = x.od_french;
                    x.nameSelect = `${x.Name} OD ${Number(x.od_french).toFixed(2)} Fr.`;
                    response.push(x);
                  }
                }
              }
              if (_el.gw_compact_mm != undefined) {
                if(x.gw_compact_mm != undefined) {
                  if(x.gw_compact_mm > _el.gw_compact_mm) {
                    response.splice(response.indexOf(_el),1);
                    x.selectType = x.od_french;
                    x.nameSelect = `${x.Name} OD ${Number(x.od_french).toFixed(2)} Fr.`;
                    response.push(x);
                  }
                }
              }
            } else {
              x.selectType = x.od_french;
              x.nameSelect = `${x.Name} OD ${Number(x.od_french).toFixed(2)} Fr.`;
              response.push(x);
            }
            //const _selectType = selectedProduct !== "a" ? x.od_french : x.id_french ? x.id_french : x.gw_compact_mm;
            //const _nameSelect = selectedProduct !== "a" ? `${x.Name} OD ${x.od_french} Fr.` : x.id_french ? `${x.Name} ID ${x.id_french} Fr.` : x.gw_compact_mm ? `${x.Name} GW ${x.gw_compact_mm} mm.` : `${x.Name} OD ${x.od_french} Fr.`;
            //const _nameSelect = `${x.Name} OD ${x.od_french} Fr.`;
            //return { ...x, selectType: _selectType, nameSelect: _nameSelect};
          });
          break;
        case 2: //"Sheaths":
          response = result.data.map((x) => {
            return { ...x, selectType: x.id_french, nameSelect: `${x.Name} ID ${Number(x.id_french).toFixed(2)} Fr.` };
          });
          break;
        /*
          case "Microcatheter":
          response = result.data.map((x) => {
            const _selectType = selectedProduct !== "a" ? x.od_french : x.id_french ? x.id_french : x.gw_compact_mm;
            //const _nameSelect = selectedProduct !== "a" ? `${x.Name} OD ${x.od_french} Fr.` : x.id_french ? `${x.Name} ID ${x.id_french} Fr.` : x.gw_compact_mm ? `${x.Name} GW ${x.gw_compact_mm} mm.` : `${x.Name} OD ${x.od_french} Fr.`;
            const _nameSelect = `${x.Name} OD ${x.od_french} Fr.`;
            return { ...x, selectType: x._selectType, nameSelect: _nameSelect };
          });
          break;
        */
        case 4: //"Cerebral Coil":
        case 5: //"Embolization Coil":
        case 6: //"Guidewires":
          //response = result.data.map((x) => {
          //  return { ...x, selectType: x.od_inches, nameSelect: `${x.Name} OD ${x.od_inches} in.` };
          //});
          //break;
          result.data.map((x) => {
            const _el = response.find(e => e.Name === x.Name && e.od_inches == x.od_inches)
            if (_el != undefined) {
              //console.log("EL: ", _el, "X: ", x);
              if (_el.id_french != undefined) {
                if(x.id_french != undefined) {
                  if(x.id_french > _el.id_french) {
                    response.splice(response.indexOf(_el),1);
                    x.selectType = x.od_french;
                    x.nameSelect = `${x.Name} OD ${Number(x.od_inches).toFixed(4)} in.`;
                    response.push(x);
                  }
                }
              }
              if (_el.gw_compact_mm != undefined) {
                if(x.gw_compact_mm != undefined) {
                  if(x.gw_compact_mm > _el.gw_compact_mm) {
                    response.splice(response.indexOf(_el),1);
                    x.selectType = x.od_french;
                    x.nameSelect = `${x.Name} OD ${Number(x.od_inches).toFixed(4)} in.`;
                    response.push(x);
                  }
                }
              }
            } else {
              x.selectType = x.od_french;
              x.nameSelect = `${x.Name} OD ${Number(x.od_inches).toFixed(4)} in.`;
              response.push(x);
            }
            //const _selectType = selectedProduct !== "a" ? x.od_french : x.id_french ? x.id_french : x.gw_compact_mm;
            //const _nameSelect = selectedProduct !== "a" ? `${x.Name} OD ${x.od_french} Fr.` : x.id_french ? `${x.Name} ID ${x.id_french} Fr.` : x.gw_compact_mm ? `${x.Name} GW ${x.gw_compact_mm} mm.` : `${x.Name} OD ${x.od_french} Fr.`;
            //const _nameSelect = `${x.Name} OD ${x.od_french} Fr.`;
            //return { ...x, selectType: _selectType, nameSelect: _nameSelect};
          });
          break;
        /*
          case "Embolization Coil":
          response = result.data.map((x) => {
            return { ...x, selectType: x.od_inches, nameSelect: `${x.Name} OD ${x.od_inches} in.` };
          });
          break;
        case "Guidewires":
          response = result.data.map((x) => {
            return { ...x, selectType: x.od_inches, nameSelect: `${x.Name} OD ${x.od_inches} in.` };
          });
          break;
          */
        case 7: //"Embolic Particle Beads":
          response = result.data.map((x) => {
            return { ...x, selectType: x.od_microns, nameSelect: `${x.Name} OD ${Number(x.od_microns).toFixed(2)} um.` };
          });
          break;
        case 8: //"Vascular Plugs":
          response = result.data.map((x) => {
            return { ...x, selectType: x.od_mm, nameSelect: `${x.Name} ID ${Number(x.od_mm).toFixed(2)} mm.`  };
          });
          break;
        case 9: //"Balloon":
          response = result.data.map((x) => {
            return { ...x, selectType: x.search_ballon_stents, nameSelect: `${x.Name} ${x.search_ballon_stents}` };
          });
          break;
        case 10: //"Stents":
          response = result.data.map((x) => {
            return { ...x, selectType: x.search_ballon_stents, nameSelect: `${x.Name} ${x.search_ballon_stents}` };
          });
          break;
        case 11: //"Atherectomy Device":
          response = result.data.map((x) => {
            return { ...x, nameSelect: x.Name};
          });
          break;
      }
    }
    setProductList(response);
    setMapType(data, selectedProduct);
  };

  const setMapType = async (data, type) => {
    if(data){
      setSelectedCatheterType(data.id);
      //localStorage.setItem("select-type" + type, `${data.id}`);
      
      if(type ==="a") {
        setTypeProductA(data.id);
      } else if (type === "b") {
        setTypeProductB(data.id);
      } else if (type === "c") {
        setTypeProductC(data.id);
      } else if (type === "d") {
        setTypeProductD(data.id);
      }

    }else{
      //const selecttype = localStorage.getItem("select-type" + type);
      
      let selecttype = "";
      if(type ==="a") {
        selecttype = typeProductA;
      } else if (type === "b") {
        selecttype = typeProductB;
      } else if (type === "c") {
        selecttype = typeProductC;
      } else if (type === "d") {
        selecttype = typeProductD;
      }

      setSelectedCatheterType(selecttype ? selecttype : "");
      if(selecttype){
        const data = filteredProductListGeneral.find(x => x.id == selecttype);
        const result = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}/products/type`,
          data: {
            Description: selecttype
          }
        });
        let response = [];
        if(data){
          //console.log("Data: ",data)
          switch (data.id) {
            case 1: //"Catheter":
              response = result.data.map((x) => {
                return { ...x, selectType: x.od_french };
              });
              break;
            case 2: //"Sheaths":
              response = result.data.map((x) => {
                return { ...x, selectType: x.id_french };
              });
              break;
            case 3: //"Microcatheter":
              response = result.data.map((x) => {
                return { ...x, selectType: x.od_french };
              });
              break;
            case 4: //"Cerebral Coil":
              response = result.data.map((x) => {
                return { ...x, selectType: x.od_inches };
              });
              break;
            case 5: //"Embolization Coil":
              response = result.data.map((x) => {
                return { ...x, selectType: x.od_inches };
              });
              break;
            case 6: //"Guidewires":
              response = result.data.map((x) => {
                return { ...x, selectType: x.od_inches };
              });
              break;
            case 7: //"Embolic Particle Beads":
              response = result.data.map((x) => {
                return { ...x, selectType: x.od_microns };
              });
              break;
            case 8: //"Vascular Plugs":
              response = result.data.map((x) => {
                return { ...x, selectType: x.od_mm };
              });
              break;
            case 9: //"Balloon":
              response = result.data.map((x) => {
                return { ...x, selectType: x.search_ballon_stents };
              });
              break;
            case 10: //"Stents":
              response = result.data.map((x) => {
                return { ...x, selectType: x.search_ballon_stents };
              });
              break;
            case 11: //"Atherectomy Device":
              response = result.data.map((x) => {
                return { ...x };
              });
              break;
          }
        }
        setProductList(response);
      }
    }
  };

  const handleSetProduct = (role, productId) => {
    //console.log(role,productId);
    let product = productList.find((i) => i.id === parseInt(productId));
    handleProduct(role, product);
  };
  
  const handleProduct = (role, product) => {
    //console.log(product);
    let name = "";
    let pt = '';
    if(product && product.selectType){
      if(isNaN(product.selectType)){
        pt = `(${product.selectType})`;
      }else{
        pt = `(${Number(product.selectType).toFixed(2)} Fr.)`;
      }
    }
    switch (role) {
      case "a":
        setIdProductA(product.id);
        setNameProductA(product.nameSelect);
        break;
      case "b":
        setIdProductB(product.id);
        setNameProductB(product.nameSelect);
        break;
      case "c":
        setIdProductC(product.id);
        setNameProductC(product.nameSelect);
        break;
      case "d":
        setIdProductD(product.id);
        setNameProductD(product.nameSelect);
        break;
      default:
        break;
    }
  }

  const NameSelect = (row) => {
    let pt = '';
    if(row && row.selectType){
      if(isNaN(row.selectType)){
        pt = `(${row.selectType})`;
      }else{
        pt = `(${Number(row.selectType).toFixed(2)} Fr.)`;
      }
    }
    return row ? `${row.Name} ${pt}` : '';
  };

  const handleCompatibility = (style) => {
    switch (catheter) {
      case "a":
        const dataA = {
          mainCatheter: idProductA,
          products: [idProductB],
          headers: authHeader(),
          style: style
        };
        axios
          .post(`${process.env.REACT_APP_API_URL}/comparer`, dataA)
          .then((res) => {
            checkIfCompatible(res.data.status, res.data.message);
            setIsVisible(false);
            setIdCompare(res.data.id);
          })
          .catch((res) => {
            //console.log("Wrong input");
          });
        break;
      case "b":
        const dataB = {
          mainCatheter: idProductA,
          products: [idProductB, idProductC],
          headers: authHeader(),
          style: style
        };
        axios
          .post(`${process.env.REACT_APP_API_URL}/comparer`, dataB)
          .then((res) => {
            checkIfCompatible(res.data.status, res.data.message);
            setIsVisible(false);
            setIdCompare(res.data.id);
          })
          .catch((res) => {
            //console.log("Wrong input");
          });
        break;
      case "c":
        const dataC = {
          mainCatheter: idProductA,
          products: [idProductB, idProductC, idProductD],
          headers: authHeader(),
          style: style
        };
        axios
          .post(`${process.env.REACT_APP_API_URL}/comparer`, dataC)
          .then((res) => {
            checkIfCompatible(res.data.status, res.data.message);
            setIsVisible(false);
            setIdCompare(res.data.id);
          })
          .catch((res) => {
            //console.log("Wrong input");
          });
        break;
      default:
        break;
    }
  };

  const handleCompatibilitysend = () => {
    handleCompatibility(true);
  };

  const TypeSend = (type) => {
    setTypeSend(type);
    setShow(true);
  };

  const checkIfCompatible = (code, message) => {
    setIsCompatible(
      <>
        <div className="compatibility-result">
          <p className={ code == 200 ? "success" : "error" }>{message}</p>
        </div>
        <div className="between">
          <a href={`/choose-catheter/${catheter}`} className="btn btn-result">
            <ReplyIcon className="result-icon-reply"/>
            Do another compatibility check
          </a>
         {/*<a href="javascript:void(0)" className="btn btn-result right dropdown_style">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <SendIcon className="result-icon"/>
                Share your compatibility result
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="javascript:void(0)" onClick={() => {TypeSend("email"); seterrorListData("")} }><MailIcon /> Email</Dropdown.Item>
                <Dropdown.Item href="javascript:void(0)" onClick={() => {TypeSend("sms"); seterrorListData("")} }><MessageIcon /> SMS</Dropdown.Item>
                <Dropdown.Item href="javascript:void(0)" onClick={() => {TypeSend("whatsapp"); seterrorListData("")} }><WhatsAppIcon /> Whatsapp</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </a>*/}
          <div className="btn btn-result">
            <SendIcon className="result-icon"/>
            Share your compatibility result. Coming Soon
          </div>
        </div>
      </>
    );
  };

  const checkIfCompleted = () => {
    switch (catheter) {
      case "a":
        return idProductA !== "" && idProductB !== "";
      case "b":
        return idProductA !== "" && idProductB !== "" && idProductC !== "";
      case "c":
        return (
          idProductA !== "" &&
          idProductB !== "" &&
          idProductC !== "" &&
          idProductD !== ""
        );
      default:
        break;
    }
  };

  const handleSelectCatheter = (e) => {
    let value = e.target.value;
    setSelectedCatheter(value);
    handleSetProduct(selectedProduct, value);
  };

  const changeSelectMulti = (e) => {
    setinputModal(e);
  };

  const validate = (email) => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
    return expression.test(String(email).toLowerCase())
  };

  const isNum = (val) => {
    return isNaN(val);
  };

  const handleSendEmail = async (e) => {
    seterrorListData("");
    if(inputModal.length === 0){
      seterrorListData(typeSend === "email" ? <span className="span-list-error">Please Enter Emails</span> : <span className="span-list-error">Please Enter Numbers</span>);
      return;
    }
    inputModal.forEach(data => {
      if(typeSend === "email"){
        if(!validate(data.value)){
          seterrorListData(<span className="span-list-error">Please Enter a Valid Emails</span>);
          return;
        }
      }else{
        if(isNum(data.value)){
          seterrorListData(<span className="span-list-error">Please Enter a Valid Numbers</span>);
          return;
        }
      }
    });
    if(typeSend !== 'email'){
      if(!countryCode){
        seterrorListData(<span className="span-list-error">Please Enter a Code Country</span>);
        return;
      }
    }
    const email = inputModal.map(function(x) { return x.value; });
    let id_comp = idCompare ? idCompare : historyid;
    if(typeSend === 'email') await axios.post(`${process.env.REACT_APP_API_URL}/users/send-data`, {email: email, type: typeSend, id: id_comp});
    if(typeSend === 'whatsapp') await axios.post(`${process.env.REACT_APP_API_URL}/users/send-data-whatsapp`, {email: email, type: typeSend, id: id_comp, code: countryCode});
    if(typeSend === 'sms') await axios.post(`${process.env.REACT_APP_API_URL}/users/send-data-sms`, {email: email, type: typeSend, id: id_comp, code: countryCode});
    Toast.fire({
      icon: 'success',
      title: "Compatibility Send"
    });
    setShow(false);
    setinputModal([]);
  };

  const generatePdf = async () => {
    const pdf = new jsPDF('p','pt','a4');

  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            { typeSend == "email" ? "Add Email" : typeSend == "sms" ? "Add Phone Number (SMS)" : "Add Phone Number (Whatsapp)" }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            typeSend == "email" ?
            <>
              <div className="select-input-only">
                <CreatableSelect placeholder={ typeSend == "email" ? "Enter Emails..." : "Enter Numbers.." } isMulti options={colourOptions} onChange={changeSelectMulti}/>
                {errorListData}
              </div>
            </>
            :
            <>
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <Select placeholder={'Code Country...'} options={data_country}  onChange={(e) => secountryCode(e.value)}/>
                </div>
                <div className="col-lg-8 mb-3">
                  <div className="select-input-only">
                    <CreatableSelect placeholder={ typeSend == "email" ? "Enter Emails..." : "Enter Numbers.." } isMulti options={colourOptions} onChange={changeSelectMulti}/>
                    {errorListData}
                  </div>
                </div>
              </div>
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}> Close </Button>
          <Button variant="primary" onClick={handleSendEmail}> Send </Button>
        </Modal.Footer>
      </Modal>
      
      <NavBarIfLoggedIn />
      <div className="background-figure-page" id="compare">
        <div className="catheter-title-figure-page">
          <h1>COMPATIBILITY CHECK</h1>
        </div>
        <div className="catheter-body">
          <div className="left-side-figure-page">
            <div className="arrow-back-position Hover">
              <NavLink to="/choose-catheter">
                <ArrowBackIcon className="arrow-back-color" />
              </NavLink>
            </div>
            <div className="catheter-position">
              <div className="catheter-position-inside">
                <CatheterForm
                  products={{ idProductA, idProductB, idProductC, idProductD }}
                  handleSelectProduct={setSelectedProduct}
                  selectedProduct={selectedProduct}
                  visibleinput={setIsVisible}
                  selectedValue={setSelectedCatheter}
                />
              </div>
            </div>
            { isVisible === true &&
              <div className="catheter-input">
                <div>
                    <Form>
                      <Form.Group controlId="exampleForm.SelectCustomSizeLg">
                        <Form.Label>Select Product Type:</Form.Label>
                        <Form.Control as="select" style={{ display: "block", width: "100%" }} custom onChange={fetchData} value={selectedCatheterType}>
                          <option>Select Product Type</option>
                          {filteredProductListGeneral.map((row) => (
                            <option className="selectText" key={row.id} value={row.id} selected={ selectedCatheterType === row.id ? true : false }>
                              {row.Name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="exampleForm.SelectCustomSizeLg">
                        <Form.Label>Select Product: <span className="textproduct">{selectedProduct}</span></Form.Label>
                        <Form.Control as="select" style={{ display: "block", width: "100%" }} custom onChange={handleSelectCatheter} value={selectedCatheter}>
                          <option>Select Product</option>
                          {productList.map((row) => (
                            <option className="selectText" key={row.id} value={row.id} selected={ selectedCatheter === row.Name ? true : false }>
                              { row.nameSelect }
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Form>
                </div>
              </div>
            }
          </div>
          <div className="right-side-figure-page">
            <div className="right-side-text-container">
              <div className="right-side-text-container-title">
                <h2>Selected Products:</h2>
              </div>
              <div className="right-side-product-display-container">
                <div className="right-side-product-display">
                  <p>
                    {idProductA && (
                      <div>
                        <p>
                          <CheckIcon className="navbar-Icon" />{" "}
                          {/*<span className="titleselected">Product A:</span> {getProductName(idProductA, 'a')}*/}
                          <span className="titleselected">Product A:</span> {nameProductA}
                        </p>
                      </div>
                    )}
                  </p>
                </div>
                <div className="right-side-product-display">
                  <p>
                    {idProductB && (
                      <div>
                        <p>
                          <CheckIcon className="navbar-Icon" />{" "}
                          {/*<span className="titleselected">Product B:</span> {getProductName(idProductB, 'b')}*/}
                          <span className="titleselected">Product B:</span> {nameProductB}
                        </p>
                      </div>
                    )}
                  </p>
                </div>
                <div className="right-side-product-display">
                  <p>
                    {idProductC && (
                      <div>
                        <p>
                          <CheckIcon className="navbar-Icon" />{" "}
                          {/*<span className="titleselected">Product C:</span> {getProductName(idProductC, 'c')}*/}
                          <span className="titleselected">Product C:</span> {nameProductC}
                        </p>
                      </div>
                    )}
                  </p>
                </div>
                <div className="right-side-product-display">
                  <p>
                    {idProductD && (
                      <div>
                        <p>
                          <CheckIcon className="navbar-Icon" />{" "}
                          {/*<span className="titleselected">Product D:</span> {getProductName(idProductD, 'd')}*/}
                          <span className="titleselected">Product D:</span> {nameProductD}
                        </p>
                      </div>
                    )}
                  </p>
                </div>
              </div>
              <div className="right-side-product-button">
                {isVisible
                  ? checkIfCompleted() && (
                      <Button
                        className="Hover"
                        variant="success"
                        onClick={() => handleCompatibilitysend()}
                      >
                        Check Compatibility!
                      </Button>
                    )
                  : isCompatible}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default CatheterA;
