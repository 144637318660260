import React from "react";
import LoginForm from "../../components/LoginForm/LoginForm";
import "../AdminMenus/AdminLogIn.css";
import "../FinalizePayPage/FinalizePayPage.css";
import Footer from "../../components/Footer/Footer";
import background from "../models/background-2.jpg";
import { NavBarIfLoggedIn } from "../../middleware/IfLoggedIn";

const Login = () => {
  return (
    <body>
      <NavBarIfLoggedIn></NavBarIfLoggedIn>
      <div className="page-title">
        <img src={background} alt="Catheter background"></img>
        <p>Access</p>
      </div>
      <div className="page-container">
        <div className="admin-log-in-container">
          <div className="title-billing">
            <h1>Access your account</h1>
          </div>
          <div
            className="hl-details"
            style={{
              marginTop: "5%",
            }}
          ></div>
          <LoginForm />
          <div className="sign-up-text">
            <p>
              Don't have an account yet? <a href="/login/subscription">Sign up!</a>
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </body>
  );
};

export default Login;
