import React from "react";
import { NavBarIfLoggedIn } from "../../middleware/IfLoggedIn";
import "./NotFound.css";

const NotFound = () => {
  return (
    <>
      <NavBarIfLoggedIn />
      <h1 className="pageNotFound">
        <b>404</b>: Page not found
      </h1>
    </>
  );
};

export default NotFound;
