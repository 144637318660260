import React, { useEffect, useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { ifUserIsAdmin } from "../middleware/IfLoggedIn";

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("authorization"));
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        getCurrentUser() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export const PrivateRouteAdminMenu = ({ component: Component, ...rest }) => {
  const [router, setRouter] = useState(<h1>Loading..</h1>);

  const checkIfUserAdmin = async () => {
    const isAdmin = await ifUserIsAdmin();
    setRouter(
      <Route
        {...rest}
        render={(props) =>
          getCurrentUser() && isAdmin ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/admin/login", state: { from: props.location } }}
            />
          )
        }
      />
    );
  };

  useEffect(() => {
    checkIfUserAdmin();
  }, []);

  return router;
};
