import { useState } from 'react';
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import background from "../models/background-2.jpg";
import axios from "axios";
import { Button } from "react-bootstrap";


const ReactivateUser = () => {
  let history = useHistory();
  const [email, setemail] = useState("");
  const [validemail, setvalidemail] = useState("");
  
  const handleError = () => {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error has occurred activating your account. Please, try again in a few minutes'
    }).then((result) => {
      if (result.isConfirmed) {
        history.push('/')
      }
    })
  }
  
  const ConfirmEmail = (e) => {
    setvalidemail("");
  
    if(email.length <= 0){
      setvalidemail(<p className="error-validation">Email required</p>);
    }

    if(email.length <= 0){
      return;
    }

    axios.get(`${process.env.REACT_APP_API_URL}/users/reactivate/${email}`).then((res) => {
      console.log(res);
      if(res.status == 200) {
        Swal.fire({
          icon: 'warning',
          title: 'Pending Account Activation',
          text: 'We have sent you an email to verify the account. Please, confirm your account before trying to login to CatheterBuddy.com'
        }).then((result) => {
          if (result.isConfirmed) {
            history.push('/')
          }
        })
      }
    }).catch((err) => {
      console.log(err)
      handleError()
    });
  }
  
  
  return (
    <body>
      <div className="page-title">
        <img src={background} alt="Catheter background"></img>
        <p>Activation Process</p>
      </div>
      <div className="page-container">
        <div className="admin-log-in-container">
          <div className="title-billing">
            <h1>Confirm your Email</h1>
          </div>
          <div className="hl-details" style={{ marginTop: "5%" }}></div>
          <div>
            <div className="row mt-4">
              <div className="col-lg-12 mb-4">
                <label className="label">Email</label>
                <input className="form-control" type="email" name="email" onChange={(e) => {   setemail(e.target.value); }} required/>
                {validemail}
              </div>
              <div className="col-lg-6 mb-4">
                <Button onClick={ConfirmEmail} variant="success">
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  )
}

export default ReactivateUser