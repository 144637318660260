import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { ifUserIsLoggedIn } from "../../middleware/IfLoggedIn";
import { useStickyState } from "../../middleware/useStickyState";
import "../../pages/AdminMenus/AdminLogIn.css";
import { authHeader } from "../../services/AuthHeader";
import { Button } from "react-bootstrap";
import axios from "axios";

const LoginForm = () => {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useStickyState("authorization", "");

  const loginFunc = (e) => {
    const data = {
      Email: email,
      Password: password,
    };
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}/users/login`, data, {
        headers: authHeader(),
      })
      .then((res) => {
        setToken(res.data.token);
        setEmail("");
        setPassword("");
        ifUserIsLoggedIn(history);
      })
      .catch((error) => {
        // if (error.response.status === 401) {
        if(error){
          if (error.response && error.response.status && error.response.status === 500) {
            Swal.fire({
              title: "Error!",
              text: error.response ? error.response.data.message : '',
              icon: "error",
              confirmButtonText: "Try Again",
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: 'There was an error, please try again later or contact the administrator',
              icon: "error",
              confirmButtonText: "Try Again",
            });
          }
        }else{
          Swal.fire({
            title: "Error!",
            text: 'There was an error, please try again later or contact the administrator',
            icon: "error",
            confirmButtonText: "Try Again",
          });
        }
      });
  };
  return (
    <div>
      <form>
        <div className="admin-log-in">
          <label className="login-text-admin Email">Email</label>
          <div className="login-input-admin">
            <input
              className="input-login-admin"
              type="email"
              name="email"
              placeholder="Enter Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className="admin-log-in">
          <label className="login-text-admin Password">Password</label>
          <div className="login-input-admin">
            <input
              className="input-login-admin"
              type="password"
              name="password"
              placeholder="Enter Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className="admin-log-in-button">
          <Button onClick={loginFunc} variant="success">
            Login
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
