import axios from "axios";
import { authHeader } from "../services/AuthHeader";
import Swal from "sweetalert2";
import { getCurrentUser } from "../services/authService";
import NavBar from "../components/NavBar/index";
import NavBarNoAccount from "../components/NavBar/NavBarNoAccount";

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});

export const ifUserIsAdmin = () => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/users/checkifadmin`, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        return true;
      }
    })
    .catch((error) => {
      if (error.response.status === 403) {
        Swal.fire({
          title: "Error!",
          text: "You are not authorized.",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return false;
      } else {
        Swal.fire({
          title: "Error!",
          text: "Whoops something went wrong!",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return false;
      }
    });
};

export const ifUserIsLoggedIn = (history) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/users/checkifadmin`, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res.status === 200) {
        history.push(`/choose-catheter`);
        Toast.fire({
          icon: 'success',
          title: "Welcome!"
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 403) {
        history.push("/choose-catheter");
        Toast.fire({
          icon: 'success',
          title: "Welcome!"
        });
      } else {
        history.push("/");
        Swal.fire({
          title: "Error!",
          text: "Whoops something went wrong!",
          icon: "error",
          confirmButtonText: "Ok",
        });
        localStorage.removeItem("authorization");
      }
    });
};

export const NavBarIfLoggedIn = () => {
  return getCurrentUser() ? (
    <NavBar></NavBar>
  ) : (
    <NavBarNoAccount></NavBarNoAccount>
  );
};
