import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import "./ChooseCatheter.css";
import axios from "axios";
import Swal from "sweetalert2";
import Footer from "../../../components/Footer/Footer";
import { authHeader } from "../../../services/AuthHeader";
import { NavBarIfLoggedIn } from "../../../middleware/IfLoggedIn";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import {Modal, Button} from "react-bootstrap";
import { useStickyState } from "../../../middleware/useStickyState";


const { REACT_APP_API_URL } = process.env;


const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});

const ChooseCatheter = () => {
  let history = useHistory();
  const [subscriptionC, setSubscriptionC] = useState(true);
  const [preloader, setPreloader] = useState(false);
  const [eula, setEula] = useState(true);
  const [jwt,setJwt] = useState({});

  
  useEffect(() => {
    const statusSession = async () => {
      await axios(`${REACT_APP_API_URL}/users/session`, { headers: authHeader() })
      .then(req => {
        //console.log(req);
        const _jwt =  JSON.parse((atob(localStorage.getItem('authorization').split(".")[1])));
        setJwt(_jwt);
        const accepted = Boolean(localStorage.getItem(_jwt.Email));
        //console.log("accepted", accepted);
        //console.log("_jwt.Eula", _jwt.Eula);
        if(!accepted) {
          localStorage.setItem(_jwt.Email, _jwt.Eula);
          setEula(_jwt.Eula);
        } else {
          setEula(true);
        }
      })
      .catch((e) => {
        if(e.response.status === 503){
          localStorage.removeItem('authorization');
          Toast.fire({
            icon: 'danger',
            title: "The current session expired"
          });
          history.push('/login');
        }
      }); 
    };
    statusSession();

    const fetchDataGeneral = async () => {
      const result = await axios.post(`${process.env.REACT_APP_API_URL}/users/subscription`, { headers: authHeader(), });
      setSubscriptionC(result.data.status_subscriptions);
      setTimeout(() => {
        setPreloader(true);
      }, 500);
      if(result.data.status_subscriptions === false){
        Toast.fire({
          icon: 'error',
          title: 'You must subscribe first'
        });
      }
    };
    fetchDataGeneral();
  }, []);

  const storeAgree = async () => {
    console.log(eula);
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/eula`, {
        headers: authHeader(),
      }).then(req => {
        if (req.status == 200) {
            setEula(req.data.Eula);
            localStorage.setItem(jwt.Email, req.data.Eula);
        }
      }).catch(err => console.log(err))
  }

  return (
    <div>
      <Modal show={!eula} backdrop="static">
        <Modal.Body>
        I understand this application Catheter Buddy is currently in developmental phase and that the content may not be fully verified or updated. Catheter Buddy is not suitable for safe clinical use. Catheter Buddy does not replace clinical judgement or expertise. Verification of catheter and device compatibility from each manufacturer instructions for use is required.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={storeAgree}>Accept</Button>
        </Modal.Footer>
      </Modal>
      {
        preloader === false ?
          <>
            <NavBarIfLoggedIn></NavBarIfLoggedIn>
            <div style={{ textAlign: "center", width: "100%", minHeight: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
              />
            </div>
            <Footer></Footer>
          </>
          :
          subscriptionC === false ? 
          <Redirect to={{ pathname: "/subscription" }} />
          :
          <>
            <NavBarIfLoggedIn></NavBarIfLoggedIn>
            <div className="homepage-container">
              <div className="homepage-text-container">
                <p>Select the Product Compatibility Check A, B or C</p>
              </div>
              <div className="homepage-catheter-selector">
                <div className="homepage-catheter-one">
                  <a href="/choose-catheter/a" style={{ textDecoration: "none" }}>
                    <div className="Hover Cursor">
                      <div className="catheter-design-one catheter-little">
                        <div className="catheter-design-inside-one"></div>
                      </div>
                      <p className="catheter-design-text">A</p>
                    </div>
                  </a>
                </div>
                <div className="homepage-catheter-one">
                  <a href="/choose-catheter/b" style={{ textDecoration: "none" }}>
                    <div className="Hover Cursor">
                      <div className="catheter-design-two catheter-little">
                        <div className="catheter-design-inside-two-one"></div>
                        <div className="catheter-design-inside-two-two"></div>
                      </div>
                      <p className="catheter-design-text-two">B</p>
                    </div>
                  </a>
                </div>
                <div className="homepage-catheter-one">
                  <a href="/choose-catheter/c" style={{ textDecoration: "none" }}>
                    <div className="Hover Cursor">
                      <div className="catheter-design-three catheter-little">
                        <div className="catheter-design-inside-three-one"></div>
                        <div className="catheter-design-inside-three-two"></div>
                        <div className="catheter-design-inside-three-three"></div>
                      </div>
                      <p className="catheter-design-text-three">C</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <Footer></Footer>
          </>
      }
    </div>
  );
};

export default ChooseCatheter;
