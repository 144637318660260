import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../services/AuthHeader";
import { useStickyState } from "../../middleware/useStickyState";
import axios from "axios";
import { useHistory } from "react-router-dom";

const AdminLoginForm = () => {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useStickyState("authorization", "");

  const adminLoginFunc = (e) => {
    const data = {
      Email: email,
      Password: password,
    };
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}/users/login`, data, {
        headers: authHeader(),
      })
      .then((res) => {
        setToken(res.data.token);
        setEmail("");
        setPassword("");
        history.push(`/admin/menu`);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Swal.fire({
            title: "Error!",
            text: error.response ? error.response.data.message : '',
            icon: "error",
            confirmButtonText: "Try Again",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: error,
            icon: "error",
            confirmButtonText: "Try Again",
          });
        }
      });
  };

  return (
    <form>
      <div className="admin-log-in">
        <label className="login-text-admin Email">Email</label>
        <div className="login-input-admin">
          <input
            className="input-login-admin"
            type="email"
            name="email"
            placeholder="Enter Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          />
        </div>
      </div>
      <div className="admin-log-in">
        <label className="login-text-admin Password">Password</label>
        <div className="login-input-admin">
          <input
            className="input-login-admin"
            type="password"
            name="password"
            placeholder="Enter Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
          />
        </div>
      </div>
      <div className="admin-log-in-button Hover">
        <Button onClick={adminLoginFunc}>Login</Button>
      </div>
    </form>
  );
};

export default AdminLoginForm;
