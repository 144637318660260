import React, { useState, useEffect } from "react";
import SideBar from "../../../components/SideBar/SideBar";
import "./AddElement.css";
import { Form, Button } from "react-bootstrap/";
import { useHistory } from "react-router";
import { authHeader } from "../../../services/AuthHeader";
import axios from "axios";
import Swal from "sweetalert2";

function AddProduct() {
  const history = useHistory();
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [searchField, setSearchField] = useState("");
  const [idmm, setidmm] = useState("");
  const [idfrench, setidfrench] = useState("");
  const [gwcompactmm, setgwcompactmm] = useState("");
  const [odmm, setodmm] = useState("");
  const [odfrench, setodfrench] = useState("");
  const [odproximalmm, setodproximalmm] = useState("");
  const [odinches, setodinches] = useState("");
  const [odmicrons, setodmicrons] = useState("");
  const [searchballonstents, setsearchballonstents] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [productTypeId, setProductTypeId] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const companyresult = await axios(
        `${process.env.REACT_APP_API_URL}/companies/`
      );
      setCompanyList(companyresult.data);
      const producttyperesult = await axios(
        `${process.env.REACT_APP_API_URL}/productTypes/`
      );
      setProductTypeList(producttyperesult.data);
    };
    fetchData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      type: type,
      description: description,
      searchField: searchField,
      idmm: idmm,
      idfrench: idfrench,
      gwcompactmm: gwcompactmm,
      odmm: odmm,
      odfrench: odfrench,
      odproximalmm: odproximalmm,
      odinches: odinches,
      odmicrons: odmicrons,
      searchballonstents: searchballonstents,
      companyId: companyId,
      productTypeId: productTypeId
    };
    e.preventDefault();
    axios.post(`${process.env.REACT_APP_API_URL}/products`, data, {
      headers: authHeader(),
    }).then((res) => {
      debugger;
      Swal.fire({
        title: "Success!",
        text: `${res.data.newProduct.Name} has been added!`,
        icon: "success",
        confirmButtonText: "Ok",
      });
      setName("");
      setType("");
      setDescription("");
      setSearchField("");
      setidmm("");
      setidfrench("");
      setgwcompactmm("");
      setodmm("");
      setodfrench("");
      setodproximalmm("");
      setodinches("");
      setodmicrons("");
      setsearchballonstents("");
      setCompanyId("");
      setProductTypeId("");
    }).catch((error) => {
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        confirmButtonText: "Try Again",
      });
    });
  };

  return (
    <div className="AppAdmin">
      <SideBar></SideBar>
      <div className="products-title">
        <p>Add Product</p>
      </div>
      <div className="input-product-container-product">
        <h2>Product</h2>
        <div class="row">
          <div class="col-md-4 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
          </div>
          <div class="col-md-4 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Type</Form.Label>
              <Form.Control
                type="text"
                value={type}
                onChange={(e) => setType(e.target.value)}
                required
              />
            </Form.Group>
          </div>
          <div class="col-md-4 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Search field</Form.Label>
              <Form.Control
                type="text"
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
                required
              />
            </Form.Group>
          </div>
          <div class="col-md-6 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Company</Form.Label>
              <Form.Control as="select" onChange={(e) => {setCompanyId(e.target.value); }}>
                <option>Select a Company</option>
                {companyList.map((company) => (
                  <option key={company.id} value={company.id}>{company.Name}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div class="col-md-6 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Product Type</Form.Label>
              <Form.Control as="select" onChange={(e) => {setProductTypeId(e.target.value); }}>
                <option>Select a Product Type</option>
                {productTypeList.map((productType) => (
                  <option key={productType.id} value={productType.id}>{productType.Name}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div class="col-md-2 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Id mm</Form.Label>
              <Form.Control
                type="text"
                value={idmm}
                onChange={(e) => setidmm(e.target.value)}
                required
              />
            </Form.Group>
          </div>
          <div class="col-md-2 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Id French</Form.Label>
              <Form.Control
                type="text"
                value={idfrench}
                onChange={(e) => setidfrench(e.target.value)}
                required
              />
            </Form.Group>
          </div>
          <div class="col-md-2 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Gw Compact mm</Form.Label>
              <Form.Control
                type="text"
                value={gwcompactmm}
                onChange={(e) => setgwcompactmm(e.target.value)}
                required
              />
            </Form.Group>
          </div>
          <div class="col-md-2 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Od mm</Form.Label>
              <Form.Control
                type="text"
                value={odmm}
                onChange={(e) => setodmm(e.target.value)}
                required
              />
            </Form.Group>
          </div>
          <div class="col-md-2 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Od French</Form.Label>
              <Form.Control
                type="text"
                value={odfrench}
                onChange={(e) => setodfrench(e.target.value)}
                required
              />
            </Form.Group>
          </div>
          <div class="col-md-2 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Od Proximal mm</Form.Label>
              <Form.Control
                type="text"
                value={odproximalmm}
                onChange={(e) => setodproximalmm(e.target.value)}
                required
              />
            </Form.Group>
          </div>
          <div class="col-md-4 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Od Inches</Form.Label>
              <Form.Control
                type="text"
                value={odinches}
                onChange={(e) => setodinches(e.target.value)}
                required
              />
            </Form.Group>
          </div>
          <div class="col-md-4 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Od Microns</Form.Label>
              <Form.Control
                type="text"
                value={odmicrons}
                onChange={(e) => setodmicrons(e.target.value)}
                required
              />
            </Form.Group>
          </div>
          <div class="col-md-4 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Search Ballon Stents</Form.Label>
              <Form.Control
                type="text"
                value={searchballonstents}
                onChange={(e) => setsearchballonstents(e.target.value)}
                required
              />
            </Form.Group>
          </div>
          <div class="col-md-12 mb-3">
            <Form.Group controlId="formGroupName">
              <Form.Label>Description</Form.Label>
                <Form.Control as="textarea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
            </Form.Group>
          </div>
        </div>
        <div className="measure-button">
          <Button size="xlg" type="button" variant="success" onClick={handleSubmit}>
            <p>Add Product</p>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
