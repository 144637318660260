import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { NavBarIfLoggedIn } from "../../middleware/IfLoggedIn";
import background from "../models/background-2.jpg";
import Footer from "../../components/Footer/Footer";
import { authHeader } from "../../services/AuthHeader";
import "./Feedback.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
const { REACT_APP_API_URL } = process.env;

const Feedback = () => {
  let history = useHistory();
  const [message, setMessage] = useState("");
  const [validate, setValidate] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  const sendFeedback = (e) => {
    const data = {
      Message: message,
    };
    e.preventDefault();
    if (message.length <= 0) {
      debugger;
      setValidate(
        <p className="password-validation">Write your feedback</p>
      );
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/users/feedback`, data, {
          headers: authHeader()
        })
        .then((res) => {
          setMessage("");
          Swal.fire({
            title: "Success!",
            text: res.data.message,
            icon: "success",
            confirmButtonText: "Ok!",
          });
          history.push("/");
        })
        .catch((error) => {
          debugger;
          Swal.fire({
            title: "Error!",
            text: error.response ? error.response.data.message : '',
            icon: "error",
            confirmButtonText: "Try Again",
          });
        });
    }
  };

  useEffect(() => {
    const statusSession = async () => {
      await axios(`${REACT_APP_API_URL}/users/session`, { headers: authHeader() }).catch((e) => {
        if(e.response.status === 503){
          localStorage.removeItem('authorization');
          Toast.fire({
            icon: 'danger',
            title: "The current session expired"
          });
          history.push('/login');
        }
      }); 
    };
    statusSession();
  }, []);

  return (
    <>
      <NavBarIfLoggedIn />
      <div className="page-title">
        <img src={background} alt="Catheter background"></img>
        <p>Feedback</p>
      </div>
      <div className="pageContainer">
        <div className="pageTextFeedback">
          <div className="title-billing">
            <h1>Submit your Feedback</h1>
          </div>
          <div className="hl-details"></div>
          <form>
          <div className="input-containers-details">
            <div className="name-input-container">
              <div className="rest-input-details">
                <Form.Group controlId="formGroupCompany">
                  <textarea
                    className="form form-control"
                    rows="10"
                    type="text" 
                    name={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }} 
                    required 
                  />
                </Form.Group>
              </div>
              {sendFeedback ? validate : ""}
            </div>
          </div>
          <Button style={{ marginLeft: "25px", background: "#199e53" }} onClick={sendFeedback}>Send</Button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Feedback;
