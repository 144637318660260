import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Homepage.
import Homepage from "./pages/HomePage/Homepage";

// Vistas con texto.
import Catheter from "./pages/CatheterPages/FigurePage/Catheter";
import Subscription from "./pages/Subscription/Subscription";
import LegalPages from "./pages/LegalPages/LegalPages";
// import FinalizePay from "./pages/FinalizePayPage/FinalizePayPage";

// Vistas funcionales.
import ChooseCatheter from "./pages/CatheterPages/ChooseCatheter/ChooseCatheter";

// Vistas de admin.
import AdminMenu from "./pages/AdminMenus/AdminMenu";
import AdminLogIn from "./pages/AdminMenus/AdminLogIn";

// Agregar objetos.
import AddCompany from "./pages/AdminMenus/AddItemsModels/AddCompany";
import AddProduct from "./pages/AdminMenus/AddItemsModels/AddProduct";
import AddMeasureType from "./pages/AdminMenus/AddItemsModels/AddMeasureType";
import AddMetricType from "./pages/AdminMenus/AddItemsModels/AddMetricType";
import AddProductType from "./pages/AdminMenus/AddItemsModels/AddProductType";

// Editar objetos.
import EditCompanies from "./pages/AdminMenus/EditModels/EditCompanies";
import EditProducts from "./pages/AdminMenus/EditModels/EditProducts";
import EditMeasureTypes from "./pages/AdminMenus/EditModels/EditMeasureTypes";
import EditMetricTypes from "./pages/AdminMenus/EditModels/EditMetricTypes";
import EditProductTypes from "./pages/AdminMenus/EditModels/EditProductTypes";

// Mostrar objetos de la db.
import SeePages from "./pages/AdminMenus/SeeModels/SeePage";

import LoginSubscriptionSimple from './pages/LoginSubscription/LoginSubscriptionSimple';
import ActivateUser from './pages/ActivateUser/ActivateUser';
import ReactivateUser from './pages/ReactivateUser/ReactivateUser';

// Logins/signUp de usuarios
import Login from "./pages/LoginPage/Login";
import SignUp from "./pages/SignUp/SignUp";

// Servicio de autenticacion.
import { PrivateRoute, PrivateRouteAdminMenu } from "./services/authService";
import Profile from "./pages/Profile/Profile";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import AddUser from "./pages/AdminMenus/AddItemsModels/AddUser";
import NotFound from "./pages/NotFound/NotFound";

//Feedback
import Feedback from "./pages/Feedback/Feedback"; 

//ExecutePayment
import ExecutePayment from "./pages/ExecutePayment/ExecutePayment";

import History from "./pages/History/index";

function App() {
  return (
    <Router>
      <Switch>
        <PrivateRouteAdminMenu path="/admin/menu" component={AdminMenu} />

        {/* Editores de objetos. */}
        <PrivateRouteAdminMenu
          path="/admin/edit-company/:id"
          component={EditCompanies}
        />
        <PrivateRouteAdminMenu
          path="/admin/edit-product/:id"
          component={EditProducts}
        />
        <PrivateRouteAdminMenu
          path="/admin/edit-measuretype/:id"
          component={EditMeasureTypes}
        />
        <PrivateRouteAdminMenu
          path="/admin/edit-metrictype/:id"
          component={EditMetricTypes}
        />
        <PrivateRouteAdminMenu
          path="/admin/edit-producttype/:id"
          component={EditProductTypes}
        />

        {/*Visualizador de tablas.*/}
        <PrivateRouteAdminMenu path="/admin/see-:models" component={SeePages} />

        {/* Agrega objetos. */}
        <PrivateRouteAdminMenu
          path="/admin/add-products"
          component={AddProduct}
        />
        <PrivateRouteAdminMenu
          path="/admin/add-companies"
          component={AddCompany}
        />
        <PrivateRouteAdminMenu
          path="/admin/add-measuretypes"
          component={AddMeasureType}
        />
        <PrivateRouteAdminMenu
          path="/admin/add-metrictype"
          component={AddMetricType}
        />
        <PrivateRouteAdminMenu
          path="/admin/add-producttypes"
          component={AddProductType}
        />
        <PrivateRouteAdminMenu path="/admin/add-users" component={AddUser} />

        {/* Login de admin. */}
        <Route path="/admin/login" component={AdminLogIn} />

        {/* Pagina de usuario regular. */}
        <PrivateRoute
          path="/choose-catheter/"
          exact
          component={ChooseCatheter}
        />
        <PrivateRoute path="/choose-catheter/:catheter/:historyid?" component={Catheter} />
        <PrivateRoute path="/profile" exact component={Profile} />
        <PrivateRoute path="/profile/change-password" exact component={ChangePassword} />
        <PrivateRoute path="/feedback" exact component={Feedback} />

        <Route path="/activate/:token" component={ActivateUser} />
        <Route path="/reactivate" exact component={ReactivateUser} />
        <Route path="/history" exact component={History} />
        <Route path="/execute-payment" exact component={ExecutePayment} />
        <Route path="/login/subscription" component={LoginSubscriptionSimple} />
        <Route path="/login" component={Login} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/forms/:forms" component={LegalPages} />
        {/* <Route path="/finalize-pay" component={FinalizePay} /> */}
        <Route path="/subscription" component={Subscription} />
        <Route path="/" exact component={Homepage} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
// <Route path="/admin/product" component={AddProduct} />
// <Route path="/admin/company" component={AddCompany} />
// <Route path="/admin/measuretypes" component={AddMetricType} />
